// This file is autogenerated by @graphql-codegen/cli
// Do not edit / add anything manually, since it will be overridden by codegen


import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@app/condo/gql/operation.types'

export * from '@app/condo/gql/operation.types'

const defaultOptions = {} as const
export const FloorInfoFragmentDoc = gql`
    fragment FloorInfo on BuildingFloor {
  id
  type
  index
  name
  units {
    id
    type
    unitType
    name
    label
    preview
  }
}
    `
export const SectionOrParkingInfoFragmentDoc = gql`
    fragment SectionOrParkingInfo on BuildingSection {
  id
  type
  index
  name
  preview
  floors {
    ...FloorInfo
  }
}
    ${FloorInfoFragmentDoc}`
export const AddressMetaForTableAddressFragmentDoc = gql`
    fragment AddressMetaForTableAddress on AddressMetaField {
  data {
    street_with_type
    house_type
    house
    block_type
    block
    flat_type
    flat
    region_type_full
    region
    region_with_type
    city_with_type
    city
    settlement_with_type
    area_with_type
  }
}
    `
export const GetBankAccountReportTasksDocument = gql`
    query getBankAccountReportTasks($where: BankAccountReportTaskWhereInput!) {
  tasks: allBankAccountReportTasks(where: $where) {
    id
    progress
    status
    __typename
  }
}
    `

/**
 * __useGetBankAccountReportTasksQuery__
 *
 * To run a query within a React component, call `useGetBankAccountReportTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountReportTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountReportTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBankAccountReportTasksQuery (baseOptions: Apollo.QueryHookOptions<Types.GetBankAccountReportTasksQuery, Types.GetBankAccountReportTasksQueryVariables> & ({ variables: Types.GetBankAccountReportTasksQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetBankAccountReportTasksQuery, Types.GetBankAccountReportTasksQueryVariables>(GetBankAccountReportTasksDocument, options)
}
export function useGetBankAccountReportTasksLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBankAccountReportTasksQuery, Types.GetBankAccountReportTasksQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetBankAccountReportTasksQuery, Types.GetBankAccountReportTasksQueryVariables>(GetBankAccountReportTasksDocument, options)
}
export function useGetBankAccountReportTasksSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetBankAccountReportTasksQuery, Types.GetBankAccountReportTasksQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetBankAccountReportTasksQuery, Types.GetBankAccountReportTasksQueryVariables>(GetBankAccountReportTasksDocument, options)
}
export type GetBankAccountReportTasksQueryHookResult = ReturnType<typeof useGetBankAccountReportTasksQuery>
export type GetBankAccountReportTasksLazyQueryHookResult = ReturnType<typeof useGetBankAccountReportTasksLazyQuery>
export type GetBankAccountReportTasksSuspenseQueryHookResult = ReturnType<typeof useGetBankAccountReportTasksSuspenseQuery>
export type GetBankAccountReportTasksQueryResult = Apollo.QueryResult<Types.GetBankAccountReportTasksQuery, Types.GetBankAccountReportTasksQueryVariables>
export const CreateBankAccountReportTaskDocument = gql`
    mutation createBankAccountReportTask($data: BankAccountReportTaskCreateInput!) {
  task: createBankAccountReportTask(data: $data) {
    id
    progress
    status
    __typename
  }
}
    `
export type CreateBankAccountReportTaskMutationFn = Apollo.MutationFunction<Types.CreateBankAccountReportTaskMutation, Types.CreateBankAccountReportTaskMutationVariables>

/**
 * __useCreateBankAccountReportTaskMutation__
 *
 * To run a mutation, you first call `useCreateBankAccountReportTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankAccountReportTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankAccountReportTaskMutation, { data, loading, error }] = useCreateBankAccountReportTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBankAccountReportTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreateBankAccountReportTaskMutation, Types.CreateBankAccountReportTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateBankAccountReportTaskMutation, Types.CreateBankAccountReportTaskMutationVariables>(CreateBankAccountReportTaskDocument, options)
}
export type CreateBankAccountReportTaskMutationHookResult = ReturnType<typeof useCreateBankAccountReportTaskMutation>
export type CreateBankAccountReportTaskMutationResult = Apollo.MutationResult<Types.CreateBankAccountReportTaskMutation>
export type CreateBankAccountReportTaskMutationOptions = Apollo.BaseMutationOptions<Types.CreateBankAccountReportTaskMutation, Types.CreateBankAccountReportTaskMutationVariables>
export const UpdateBankAccountReportTaskDocument = gql`
    mutation updateBankAccountReportTask($id: ID!, $data: BankAccountReportTaskUpdateInput!) {
  task: updateBankAccountReportTask(data: $data, id: $id) {
    id
    progress
    status
    __typename
  }
}
    `
export type UpdateBankAccountReportTaskMutationFn = Apollo.MutationFunction<Types.UpdateBankAccountReportTaskMutation, Types.UpdateBankAccountReportTaskMutationVariables>

/**
 * __useUpdateBankAccountReportTaskMutation__
 *
 * To run a mutation, you first call `useUpdateBankAccountReportTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankAccountReportTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankAccountReportTaskMutation, { data, loading, error }] = useUpdateBankAccountReportTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBankAccountReportTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateBankAccountReportTaskMutation, Types.UpdateBankAccountReportTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateBankAccountReportTaskMutation, Types.UpdateBankAccountReportTaskMutationVariables>(UpdateBankAccountReportTaskDocument, options)
}
export type UpdateBankAccountReportTaskMutationHookResult = ReturnType<typeof useUpdateBankAccountReportTaskMutation>
export type UpdateBankAccountReportTaskMutationResult = Apollo.MutationResult<Types.UpdateBankAccountReportTaskMutation>
export type UpdateBankAccountReportTaskMutationOptions = Apollo.BaseMutationOptions<Types.UpdateBankAccountReportTaskMutation, Types.UpdateBankAccountReportTaskMutationVariables>
export const GetBankSyncTasksDocument = gql`
    query getBankSyncTasks($where: BankSyncTaskWhereInput!) {
  tasks: allBankSyncTasks(where: $where) {
    id
    status
    processedCount
    totalCount
    meta
    property {
      id
    }
    options {
      type
    }
    file {
      publicUrl
      originalFilename
    }
    __typename
  }
}
    `

/**
 * __useGetBankSyncTasksQuery__
 *
 * To run a query within a React component, call `useGetBankSyncTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankSyncTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankSyncTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBankSyncTasksQuery (baseOptions: Apollo.QueryHookOptions<Types.GetBankSyncTasksQuery, Types.GetBankSyncTasksQueryVariables> & ({ variables: Types.GetBankSyncTasksQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetBankSyncTasksQuery, Types.GetBankSyncTasksQueryVariables>(GetBankSyncTasksDocument, options)
}
export function useGetBankSyncTasksLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBankSyncTasksQuery, Types.GetBankSyncTasksQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetBankSyncTasksQuery, Types.GetBankSyncTasksQueryVariables>(GetBankSyncTasksDocument, options)
}
export function useGetBankSyncTasksSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetBankSyncTasksQuery, Types.GetBankSyncTasksQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetBankSyncTasksQuery, Types.GetBankSyncTasksQueryVariables>(GetBankSyncTasksDocument, options)
}
export type GetBankSyncTasksQueryHookResult = ReturnType<typeof useGetBankSyncTasksQuery>
export type GetBankSyncTasksLazyQueryHookResult = ReturnType<typeof useGetBankSyncTasksLazyQuery>
export type GetBankSyncTasksSuspenseQueryHookResult = ReturnType<typeof useGetBankSyncTasksSuspenseQuery>
export type GetBankSyncTasksQueryResult = Apollo.QueryResult<Types.GetBankSyncTasksQuery, Types.GetBankSyncTasksQueryVariables>
export const CreateBankSyncTaskDocument = gql`
    mutation createBankSyncTask($data: BankSyncTaskCreateInput!) {
  task: createBankSyncTask(data: $data) {
    id
    status
    processedCount
    totalCount
    meta
    property {
      id
    }
    options {
      type
    }
    file {
      publicUrl
      originalFilename
    }
    __typename
  }
}
    `
export type CreateBankSyncTaskMutationFn = Apollo.MutationFunction<Types.CreateBankSyncTaskMutation, Types.CreateBankSyncTaskMutationVariables>

/**
 * __useCreateBankSyncTaskMutation__
 *
 * To run a mutation, you first call `useCreateBankSyncTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankSyncTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankSyncTaskMutation, { data, loading, error }] = useCreateBankSyncTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBankSyncTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreateBankSyncTaskMutation, Types.CreateBankSyncTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateBankSyncTaskMutation, Types.CreateBankSyncTaskMutationVariables>(CreateBankSyncTaskDocument, options)
}
export type CreateBankSyncTaskMutationHookResult = ReturnType<typeof useCreateBankSyncTaskMutation>
export type CreateBankSyncTaskMutationResult = Apollo.MutationResult<Types.CreateBankSyncTaskMutation>
export type CreateBankSyncTaskMutationOptions = Apollo.BaseMutationOptions<Types.CreateBankSyncTaskMutation, Types.CreateBankSyncTaskMutationVariables>
export const UpdateBankSyncTaskDocument = gql`
    mutation updateBankSyncTask($id: ID!, $data: BankSyncTaskUpdateInput!) {
  task: updateBankSyncTask(data: $data, id: $id) {
    id
    status
    processedCount
    totalCount
    meta
    property {
      id
    }
    options {
      type
    }
    file {
      publicUrl
      originalFilename
    }
    __typename
  }
}
    `
export type UpdateBankSyncTaskMutationFn = Apollo.MutationFunction<Types.UpdateBankSyncTaskMutation, Types.UpdateBankSyncTaskMutationVariables>

/**
 * __useUpdateBankSyncTaskMutation__
 *
 * To run a mutation, you first call `useUpdateBankSyncTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankSyncTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankSyncTaskMutation, { data, loading, error }] = useUpdateBankSyncTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBankSyncTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateBankSyncTaskMutation, Types.UpdateBankSyncTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateBankSyncTaskMutation, Types.UpdateBankSyncTaskMutationVariables>(UpdateBankSyncTaskDocument, options)
}
export type UpdateBankSyncTaskMutationHookResult = ReturnType<typeof useUpdateBankSyncTaskMutation>
export type UpdateBankSyncTaskMutationResult = Apollo.MutationResult<Types.UpdateBankSyncTaskMutation>
export type UpdateBankSyncTaskMutationOptions = Apollo.BaseMutationOptions<Types.UpdateBankSyncTaskMutation, Types.UpdateBankSyncTaskMutationVariables>
export const GetProcessingTasksDocument = gql`
    query getProcessingTasks($userId: ID!, $createdAtGte: String!) {
  allTicketDocumentGenerationTasks(
    where: {status: processing, user: {id: $userId}, createdAt_gte: $createdAtGte}
  ) {
    id
    progress
    status
    file {
      publicUrl
      originalFilename
    }
    __typename
  }
  allTicketExportTasks(
    where: {status: processing, user: {id: $userId}, createdAt_gte: $createdAtGte}
  ) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
  allIncidentExportTasks(
    where: {status: processing, user: {id: $userId}, createdAt_gte: $createdAtGte}
  ) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
  allContactExportTasks(
    where: {status: processing, user: {id: $userId}, createdAt_gte: $createdAtGte}
  ) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
  allBankSyncTasks(
    where: {status: processing, user: {id: $userId}, createdAt_gte: $createdAtGte}
  ) {
    id
    status
    processedCount
    totalCount
    meta
    property {
      id
    }
    options {
      type
    }
    file {
      publicUrl
      originalFilename
    }
    __typename
  }
  allBankAccountReportTasks(
    where: {status: processing, user: {id: $userId}, createdAt_gte: $createdAtGte}
  ) {
    id
    progress
    status
    __typename
  }
  allNewsItemRecipientsExportTasks(
    where: {status: processing, user: {id: $userId}, createdAt_gte: $createdAtGte}
  ) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    __typename
  }
  allMeterReadingsImportTasks(
    where: {status: processing, user: {id: $userId}, createdAt_gte: $createdAtGte}
  ) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    errorFile {
      publicUrl
    }
    errorMessage
    totalRecordsCount
    processedRecordsCount
    __typename
  }
  allMeterReadingExportTasks(
    where: {status: processing, user: {id: $userId}, createdAt_gte: $createdAtGte}
  ) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
}
    `

/**
 * __useGetProcessingTasksQuery__
 *
 * To run a query within a React component, call `useGetProcessingTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProcessingTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProcessingTasksQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      createdAtGte: // value for 'createdAtGte'
 *   },
 * });
 */
export function useGetProcessingTasksQuery (baseOptions: Apollo.QueryHookOptions<Types.GetProcessingTasksQuery, Types.GetProcessingTasksQueryVariables> & ({ variables: Types.GetProcessingTasksQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetProcessingTasksQuery, Types.GetProcessingTasksQueryVariables>(GetProcessingTasksDocument, options)
}
export function useGetProcessingTasksLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProcessingTasksQuery, Types.GetProcessingTasksQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetProcessingTasksQuery, Types.GetProcessingTasksQueryVariables>(GetProcessingTasksDocument, options)
}
export function useGetProcessingTasksSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetProcessingTasksQuery, Types.GetProcessingTasksQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetProcessingTasksQuery, Types.GetProcessingTasksQueryVariables>(GetProcessingTasksDocument, options)
}
export type GetProcessingTasksQueryHookResult = ReturnType<typeof useGetProcessingTasksQuery>
export type GetProcessingTasksLazyQueryHookResult = ReturnType<typeof useGetProcessingTasksLazyQuery>
export type GetProcessingTasksSuspenseQueryHookResult = ReturnType<typeof useGetProcessingTasksSuspenseQuery>
export type GetProcessingTasksQueryResult = Apollo.QueryResult<Types.GetProcessingTasksQuery, Types.GetProcessingTasksQueryVariables>
export const GetContactByIdDocument = gql`
    query getContactById($id: ID!) {
  contacts: allContacts(where: {id: $id}) {
    id
    name
    email
    phone
    unitName
    unitType
    isVerified
    organization {
      id
    }
    property {
      id
      address
    }
    role {
      id
      name
    }
  }
}
    `

/**
 * __useGetContactByIdQuery__
 *
 * To run a query within a React component, call `useGetContactByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactByIdQuery (baseOptions: Apollo.QueryHookOptions<Types.GetContactByIdQuery, Types.GetContactByIdQueryVariables> & ({ variables: Types.GetContactByIdQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetContactByIdQuery, Types.GetContactByIdQueryVariables>(GetContactByIdDocument, options)
}
export function useGetContactByIdLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContactByIdQuery, Types.GetContactByIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetContactByIdQuery, Types.GetContactByIdQueryVariables>(GetContactByIdDocument, options)
}
export function useGetContactByIdSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetContactByIdQuery, Types.GetContactByIdQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetContactByIdQuery, Types.GetContactByIdQueryVariables>(GetContactByIdDocument, options)
}
export type GetContactByIdQueryHookResult = ReturnType<typeof useGetContactByIdQuery>
export type GetContactByIdLazyQueryHookResult = ReturnType<typeof useGetContactByIdLazyQuery>
export type GetContactByIdSuspenseQueryHookResult = ReturnType<typeof useGetContactByIdSuspenseQuery>
export type GetContactByIdQueryResult = Apollo.QueryResult<Types.GetContactByIdQuery, Types.GetContactByIdQueryVariables>
export const GetContactsExistenceDocument = gql`
    query getContactsExistence($where: ContactWhereInput) {
  contacts: allContacts(first: 1, where: $where) {
    id
  }
}
    `

/**
 * __useGetContactsExistenceQuery__
 *
 * To run a query within a React component, call `useGetContactsExistenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsExistenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsExistenceQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetContactsExistenceQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetContactsExistenceQuery, Types.GetContactsExistenceQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetContactsExistenceQuery, Types.GetContactsExistenceQueryVariables>(GetContactsExistenceDocument, options)
}
export function useGetContactsExistenceLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContactsExistenceQuery, Types.GetContactsExistenceQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetContactsExistenceQuery, Types.GetContactsExistenceQueryVariables>(GetContactsExistenceDocument, options)
}
export function useGetContactsExistenceSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetContactsExistenceQuery, Types.GetContactsExistenceQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetContactsExistenceQuery, Types.GetContactsExistenceQueryVariables>(GetContactsExistenceDocument, options)
}
export type GetContactsExistenceQueryHookResult = ReturnType<typeof useGetContactsExistenceQuery>
export type GetContactsExistenceLazyQueryHookResult = ReturnType<typeof useGetContactsExistenceLazyQuery>
export type GetContactsExistenceSuspenseQueryHookResult = ReturnType<typeof useGetContactsExistenceSuspenseQuery>
export type GetContactsExistenceQueryResult = Apollo.QueryResult<Types.GetContactsExistenceQuery, Types.GetContactsExistenceQueryVariables>
export const GetContactsForTableDocument = gql`
    query getContactsForTable($where: ContactWhereInput, $first: Int, $skip: Int, $sortBy: [SortContactsBy!]) {
  contacts: allContacts(
    where: $where
    first: $first
    skip: $skip
    sortBy: $sortBy
  ) {
    id
    name
    unitName
    unitType
    phone
    email
    organization {
      id
      name
    }
    property {
      id
      deletedAt
      address
      addressMeta {
        ...AddressMetaForTableAddress
      }
    }
    role {
      id
      name
    }
  }
  meta: _allContactsMeta(where: $where) {
    count
  }
}
    ${AddressMetaForTableAddressFragmentDoc}`

/**
 * __useGetContactsForTableQuery__
 *
 * To run a query within a React component, call `useGetContactsForTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsForTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsForTableQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetContactsForTableQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetContactsForTableQuery, Types.GetContactsForTableQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetContactsForTableQuery, Types.GetContactsForTableQueryVariables>(GetContactsForTableDocument, options)
}
export function useGetContactsForTableLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContactsForTableQuery, Types.GetContactsForTableQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetContactsForTableQuery, Types.GetContactsForTableQueryVariables>(GetContactsForTableDocument, options)
}
export function useGetContactsForTableSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetContactsForTableQuery, Types.GetContactsForTableQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetContactsForTableQuery, Types.GetContactsForTableQueryVariables>(GetContactsForTableDocument, options)
}
export type GetContactsForTableQueryHookResult = ReturnType<typeof useGetContactsForTableQuery>
export type GetContactsForTableLazyQueryHookResult = ReturnType<typeof useGetContactsForTableLazyQuery>
export type GetContactsForTableSuspenseQueryHookResult = ReturnType<typeof useGetContactsForTableSuspenseQuery>
export type GetContactsForTableQueryResult = Apollo.QueryResult<Types.GetContactsForTableQuery, Types.GetContactsForTableQueryVariables>
export const GetContactEditorContactsDocument = gql`
    query getContactEditorContacts($where: ContactWhereInput) {
  contacts: allContacts(where: $where, first: 100) {
    id
    phone
    name
  }
}
    `

/**
 * __useGetContactEditorContactsQuery__
 *
 * To run a query within a React component, call `useGetContactEditorContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactEditorContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactEditorContactsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetContactEditorContactsQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetContactEditorContactsQuery, Types.GetContactEditorContactsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetContactEditorContactsQuery, Types.GetContactEditorContactsQueryVariables>(GetContactEditorContactsDocument, options)
}
export function useGetContactEditorContactsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContactEditorContactsQuery, Types.GetContactEditorContactsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetContactEditorContactsQuery, Types.GetContactEditorContactsQueryVariables>(GetContactEditorContactsDocument, options)
}
export function useGetContactEditorContactsSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetContactEditorContactsQuery, Types.GetContactEditorContactsQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetContactEditorContactsQuery, Types.GetContactEditorContactsQueryVariables>(GetContactEditorContactsDocument, options)
}
export type GetContactEditorContactsQueryHookResult = ReturnType<typeof useGetContactEditorContactsQuery>
export type GetContactEditorContactsLazyQueryHookResult = ReturnType<typeof useGetContactEditorContactsLazyQuery>
export type GetContactEditorContactsSuspenseQueryHookResult = ReturnType<typeof useGetContactEditorContactsSuspenseQuery>
export type GetContactEditorContactsQueryResult = Apollo.QueryResult<Types.GetContactEditorContactsQuery, Types.GetContactEditorContactsQueryVariables>
export const CreateContactDocument = gql`
    mutation createContact($data: ContactCreateInput!) {
  contact: createContact(data: $data) {
    id
    phone
    unitName
    unitType
    property {
      id
    }
  }
}
    `
export type CreateContactMutationFn = Apollo.MutationFunction<Types.CreateContactMutation, Types.CreateContactMutationVariables>

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateContactMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreateContactMutation, Types.CreateContactMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateContactMutation, Types.CreateContactMutationVariables>(CreateContactDocument, options)
}
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>
export type CreateContactMutationResult = Apollo.MutationResult<Types.CreateContactMutation>
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<Types.CreateContactMutation, Types.CreateContactMutationVariables>
export const UpdateContactDocument = gql`
    mutation updateContact($id: ID!, $data: ContactUpdateInput!) {
  contact: updateContact(id: $id, data: $data) {
    id
    phone
    unitName
    unitType
    property {
      id
    }
  }
}
    `
export type UpdateContactMutationFn = Apollo.MutationFunction<Types.UpdateContactMutation, Types.UpdateContactMutationVariables>

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateContactMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateContactMutation, Types.UpdateContactMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateContactMutation, Types.UpdateContactMutationVariables>(UpdateContactDocument, options)
}
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>
export type UpdateContactMutationResult = Apollo.MutationResult<Types.UpdateContactMutation>
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<Types.UpdateContactMutation, Types.UpdateContactMutationVariables>
export const GetContactExportTasksDocument = gql`
    query getContactExportTasks($where: ContactExportTaskWhereInput!) {
  tasks: allContactExportTasks(where: $where) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
}
    `

/**
 * __useGetContactExportTasksQuery__
 *
 * To run a query within a React component, call `useGetContactExportTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactExportTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactExportTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetContactExportTasksQuery (baseOptions: Apollo.QueryHookOptions<Types.GetContactExportTasksQuery, Types.GetContactExportTasksQueryVariables> & ({ variables: Types.GetContactExportTasksQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetContactExportTasksQuery, Types.GetContactExportTasksQueryVariables>(GetContactExportTasksDocument, options)
}
export function useGetContactExportTasksLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContactExportTasksQuery, Types.GetContactExportTasksQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetContactExportTasksQuery, Types.GetContactExportTasksQueryVariables>(GetContactExportTasksDocument, options)
}
export function useGetContactExportTasksSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetContactExportTasksQuery, Types.GetContactExportTasksQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetContactExportTasksQuery, Types.GetContactExportTasksQueryVariables>(GetContactExportTasksDocument, options)
}
export type GetContactExportTasksQueryHookResult = ReturnType<typeof useGetContactExportTasksQuery>
export type GetContactExportTasksLazyQueryHookResult = ReturnType<typeof useGetContactExportTasksLazyQuery>
export type GetContactExportTasksSuspenseQueryHookResult = ReturnType<typeof useGetContactExportTasksSuspenseQuery>
export type GetContactExportTasksQueryResult = Apollo.QueryResult<Types.GetContactExportTasksQuery, Types.GetContactExportTasksQueryVariables>
export const CreateContactExportTaskDocument = gql`
    mutation createContactExportTask($data: ContactExportTaskCreateInput!) {
  task: createContactExportTask(data: $data) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
}
    `
export type CreateContactExportTaskMutationFn = Apollo.MutationFunction<Types.CreateContactExportTaskMutation, Types.CreateContactExportTaskMutationVariables>

/**
 * __useCreateContactExportTaskMutation__
 *
 * To run a mutation, you first call `useCreateContactExportTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactExportTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactExportTaskMutation, { data, loading, error }] = useCreateContactExportTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateContactExportTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreateContactExportTaskMutation, Types.CreateContactExportTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateContactExportTaskMutation, Types.CreateContactExportTaskMutationVariables>(CreateContactExportTaskDocument, options)
}
export type CreateContactExportTaskMutationHookResult = ReturnType<typeof useCreateContactExportTaskMutation>
export type CreateContactExportTaskMutationResult = Apollo.MutationResult<Types.CreateContactExportTaskMutation>
export type CreateContactExportTaskMutationOptions = Apollo.BaseMutationOptions<Types.CreateContactExportTaskMutation, Types.CreateContactExportTaskMutationVariables>
export const UpdateContactExportTaskDocument = gql`
    mutation updateContactExportTask($id: ID!, $data: ContactExportTaskUpdateInput!) {
  task: updateContactExportTask(data: $data, id: $id) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
}
    `
export type UpdateContactExportTaskMutationFn = Apollo.MutationFunction<Types.UpdateContactExportTaskMutation, Types.UpdateContactExportTaskMutationVariables>

/**
 * __useUpdateContactExportTaskMutation__
 *
 * To run a mutation, you first call `useUpdateContactExportTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactExportTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactExportTaskMutation, { data, loading, error }] = useUpdateContactExportTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateContactExportTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateContactExportTaskMutation, Types.UpdateContactExportTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateContactExportTaskMutation, Types.UpdateContactExportTaskMutationVariables>(UpdateContactExportTaskDocument, options)
}
export type UpdateContactExportTaskMutationHookResult = ReturnType<typeof useUpdateContactExportTaskMutation>
export type UpdateContactExportTaskMutationResult = Apollo.MutationResult<Types.UpdateContactExportTaskMutation>
export type UpdateContactExportTaskMutationOptions = Apollo.BaseMutationOptions<Types.UpdateContactExportTaskMutation, Types.UpdateContactExportTaskMutationVariables>
export const GetCommonOrOrganizationContactRolesDocument = gql`
    query getCommonOrOrganizationContactRoles($organizationId: ID!) {
  roles: allContactRoles(
    where: {OR: [{organization_is_null: true}, {organization: {id: $organizationId}}]}
  ) {
    id
    name
  }
}
    `

/**
 * __useGetCommonOrOrganizationContactRolesQuery__
 *
 * To run a query within a React component, call `useGetCommonOrOrganizationContactRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommonOrOrganizationContactRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommonOrOrganizationContactRolesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetCommonOrOrganizationContactRolesQuery (baseOptions: Apollo.QueryHookOptions<Types.GetCommonOrOrganizationContactRolesQuery, Types.GetCommonOrOrganizationContactRolesQueryVariables> & ({ variables: Types.GetCommonOrOrganizationContactRolesQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetCommonOrOrganizationContactRolesQuery, Types.GetCommonOrOrganizationContactRolesQueryVariables>(GetCommonOrOrganizationContactRolesDocument, options)
}
export function useGetCommonOrOrganizationContactRolesLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCommonOrOrganizationContactRolesQuery, Types.GetCommonOrOrganizationContactRolesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetCommonOrOrganizationContactRolesQuery, Types.GetCommonOrOrganizationContactRolesQueryVariables>(GetCommonOrOrganizationContactRolesDocument, options)
}
export function useGetCommonOrOrganizationContactRolesSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetCommonOrOrganizationContactRolesQuery, Types.GetCommonOrOrganizationContactRolesQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetCommonOrOrganizationContactRolesQuery, Types.GetCommonOrOrganizationContactRolesQueryVariables>(GetCommonOrOrganizationContactRolesDocument, options)
}
export type GetCommonOrOrganizationContactRolesQueryHookResult = ReturnType<typeof useGetCommonOrOrganizationContactRolesQuery>
export type GetCommonOrOrganizationContactRolesLazyQueryHookResult = ReturnType<typeof useGetCommonOrOrganizationContactRolesLazyQuery>
export type GetCommonOrOrganizationContactRolesSuspenseQueryHookResult = ReturnType<typeof useGetCommonOrOrganizationContactRolesSuspenseQuery>
export type GetCommonOrOrganizationContactRolesQueryResult = Apollo.QueryResult<Types.GetCommonOrOrganizationContactRolesQuery, Types.GetCommonOrOrganizationContactRolesQueryVariables>
export const GetTicketInvoicesDocument = gql`
    query getTicketInvoices($ticketId: ID!) {
  invoices: allInvoices(
    where: {ticket: {id: $ticketId}}
    sortBy: [createdAt_DESC]
  ) {
    id
    status
    number
    paymentType
    status
    currencyCode
    rows {
      name
      toPay
      isMin
      count
      sku
    }
    organization {
      id
    }
    createdBy {
      id
      name
      type
    }
    ticket {
      id
    }
  }
}
    `

/**
 * __useGetTicketInvoicesQuery__
 *
 * To run a query within a React component, call `useGetTicketInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketInvoicesQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGetTicketInvoicesQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketInvoicesQuery, Types.GetTicketInvoicesQueryVariables> & ({ variables: Types.GetTicketInvoicesQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketInvoicesQuery, Types.GetTicketInvoicesQueryVariables>(GetTicketInvoicesDocument, options)
}
export function useGetTicketInvoicesLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketInvoicesQuery, Types.GetTicketInvoicesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketInvoicesQuery, Types.GetTicketInvoicesQueryVariables>(GetTicketInvoicesDocument, options)
}
export function useGetTicketInvoicesSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketInvoicesQuery, Types.GetTicketInvoicesQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketInvoicesQuery, Types.GetTicketInvoicesQueryVariables>(GetTicketInvoicesDocument, options)
}
export type GetTicketInvoicesQueryHookResult = ReturnType<typeof useGetTicketInvoicesQuery>
export type GetTicketInvoicesLazyQueryHookResult = ReturnType<typeof useGetTicketInvoicesLazyQuery>
export type GetTicketInvoicesSuspenseQueryHookResult = ReturnType<typeof useGetTicketInvoicesSuspenseQuery>
export type GetTicketInvoicesQueryResult = Apollo.QueryResult<Types.GetTicketInvoicesQuery, Types.GetTicketInvoicesQueryVariables>
export const GetInvoicesByIdsDocument = gql`
    query getInvoicesByIds($ids: [ID!]!) {
  invoices: allInvoices(where: {id_in: $ids}, sortBy: [createdAt_DESC]) {
    id
    status
    number
    paymentType
    status
    currencyCode
    rows {
      name
      toPay
      isMin
      count
      sku
    }
    organization {
      id
    }
    createdBy {
      id
      name
      type
    }
    ticket {
      id
    }
  }
}
    `

/**
 * __useGetInvoicesByIdsQuery__
 *
 * To run a query within a React component, call `useGetInvoicesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetInvoicesByIdsQuery (baseOptions: Apollo.QueryHookOptions<Types.GetInvoicesByIdsQuery, Types.GetInvoicesByIdsQueryVariables> & ({ variables: Types.GetInvoicesByIdsQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetInvoicesByIdsQuery, Types.GetInvoicesByIdsQueryVariables>(GetInvoicesByIdsDocument, options)
}
export function useGetInvoicesByIdsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInvoicesByIdsQuery, Types.GetInvoicesByIdsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetInvoicesByIdsQuery, Types.GetInvoicesByIdsQueryVariables>(GetInvoicesByIdsDocument, options)
}
export function useGetInvoicesByIdsSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetInvoicesByIdsQuery, Types.GetInvoicesByIdsQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetInvoicesByIdsQuery, Types.GetInvoicesByIdsQueryVariables>(GetInvoicesByIdsDocument, options)
}
export type GetInvoicesByIdsQueryHookResult = ReturnType<typeof useGetInvoicesByIdsQuery>
export type GetInvoicesByIdsLazyQueryHookResult = ReturnType<typeof useGetInvoicesByIdsLazyQuery>
export type GetInvoicesByIdsSuspenseQueryHookResult = ReturnType<typeof useGetInvoicesByIdsSuspenseQuery>
export type GetInvoicesByIdsQueryResult = Apollo.QueryResult<Types.GetInvoicesByIdsQuery, Types.GetInvoicesByIdsQueryVariables>
export const GetMeterReadingExportTasksDocument = gql`
    query getMeterReadingExportTasks($where: MeterReadingExportTaskWhereInput!) {
  tasks: allMeterReadingExportTasks(where: $where) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
}
    `

/**
 * __useGetMeterReadingExportTasksQuery__
 *
 * To run a query within a React component, call `useGetMeterReadingExportTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeterReadingExportTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeterReadingExportTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMeterReadingExportTasksQuery (baseOptions: Apollo.QueryHookOptions<Types.GetMeterReadingExportTasksQuery, Types.GetMeterReadingExportTasksQueryVariables> & ({ variables: Types.GetMeterReadingExportTasksQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetMeterReadingExportTasksQuery, Types.GetMeterReadingExportTasksQueryVariables>(GetMeterReadingExportTasksDocument, options)
}
export function useGetMeterReadingExportTasksLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMeterReadingExportTasksQuery, Types.GetMeterReadingExportTasksQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetMeterReadingExportTasksQuery, Types.GetMeterReadingExportTasksQueryVariables>(GetMeterReadingExportTasksDocument, options)
}
export function useGetMeterReadingExportTasksSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetMeterReadingExportTasksQuery, Types.GetMeterReadingExportTasksQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetMeterReadingExportTasksQuery, Types.GetMeterReadingExportTasksQueryVariables>(GetMeterReadingExportTasksDocument, options)
}
export type GetMeterReadingExportTasksQueryHookResult = ReturnType<typeof useGetMeterReadingExportTasksQuery>
export type GetMeterReadingExportTasksLazyQueryHookResult = ReturnType<typeof useGetMeterReadingExportTasksLazyQuery>
export type GetMeterReadingExportTasksSuspenseQueryHookResult = ReturnType<typeof useGetMeterReadingExportTasksSuspenseQuery>
export type GetMeterReadingExportTasksQueryResult = Apollo.QueryResult<Types.GetMeterReadingExportTasksQuery, Types.GetMeterReadingExportTasksQueryVariables>
export const CreateMeterReadingExportTaskDocument = gql`
    mutation createMeterReadingExportTask($data: MeterReadingExportTaskCreateInput!) {
  task: createMeterReadingExportTask(data: $data) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
}
    `
export type CreateMeterReadingExportTaskMutationFn = Apollo.MutationFunction<Types.CreateMeterReadingExportTaskMutation, Types.CreateMeterReadingExportTaskMutationVariables>

/**
 * __useCreateMeterReadingExportTaskMutation__
 *
 * To run a mutation, you first call `useCreateMeterReadingExportTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMeterReadingExportTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMeterReadingExportTaskMutation, { data, loading, error }] = useCreateMeterReadingExportTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMeterReadingExportTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreateMeterReadingExportTaskMutation, Types.CreateMeterReadingExportTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateMeterReadingExportTaskMutation, Types.CreateMeterReadingExportTaskMutationVariables>(CreateMeterReadingExportTaskDocument, options)
}
export type CreateMeterReadingExportTaskMutationHookResult = ReturnType<typeof useCreateMeterReadingExportTaskMutation>
export type CreateMeterReadingExportTaskMutationResult = Apollo.MutationResult<Types.CreateMeterReadingExportTaskMutation>
export type CreateMeterReadingExportTaskMutationOptions = Apollo.BaseMutationOptions<Types.CreateMeterReadingExportTaskMutation, Types.CreateMeterReadingExportTaskMutationVariables>
export const UpdateMeterReadingExportTaskDocument = gql`
    mutation updateMeterReadingExportTask($id: ID!, $data: MeterReadingExportTaskUpdateInput!) {
  task: updateMeterReadingExportTask(data: $data, id: $id) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
}
    `
export type UpdateMeterReadingExportTaskMutationFn = Apollo.MutationFunction<Types.UpdateMeterReadingExportTaskMutation, Types.UpdateMeterReadingExportTaskMutationVariables>

/**
 * __useUpdateMeterReadingExportTaskMutation__
 *
 * To run a mutation, you first call `useUpdateMeterReadingExportTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeterReadingExportTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeterReadingExportTaskMutation, { data, loading, error }] = useUpdateMeterReadingExportTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMeterReadingExportTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateMeterReadingExportTaskMutation, Types.UpdateMeterReadingExportTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateMeterReadingExportTaskMutation, Types.UpdateMeterReadingExportTaskMutationVariables>(UpdateMeterReadingExportTaskDocument, options)
}
export type UpdateMeterReadingExportTaskMutationHookResult = ReturnType<typeof useUpdateMeterReadingExportTaskMutation>
export type UpdateMeterReadingExportTaskMutationResult = Apollo.MutationResult<Types.UpdateMeterReadingExportTaskMutation>
export type UpdateMeterReadingExportTaskMutationOptions = Apollo.BaseMutationOptions<Types.UpdateMeterReadingExportTaskMutation, Types.UpdateMeterReadingExportTaskMutationVariables>
export const GetMeterReadingsImportTasksDocument = gql`
    query getMeterReadingsImportTasks($where: MeterReadingsImportTaskWhereInput!) {
  tasks: allMeterReadingsImportTasks(where: $where) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    errorFile {
      publicUrl
    }
    errorMessage
    totalRecordsCount
    processedRecordsCount
    __typename
  }
}
    `

/**
 * __useGetMeterReadingsImportTasksQuery__
 *
 * To run a query within a React component, call `useGetMeterReadingsImportTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeterReadingsImportTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeterReadingsImportTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMeterReadingsImportTasksQuery (baseOptions: Apollo.QueryHookOptions<Types.GetMeterReadingsImportTasksQuery, Types.GetMeterReadingsImportTasksQueryVariables> & ({ variables: Types.GetMeterReadingsImportTasksQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetMeterReadingsImportTasksQuery, Types.GetMeterReadingsImportTasksQueryVariables>(GetMeterReadingsImportTasksDocument, options)
}
export function useGetMeterReadingsImportTasksLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMeterReadingsImportTasksQuery, Types.GetMeterReadingsImportTasksQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetMeterReadingsImportTasksQuery, Types.GetMeterReadingsImportTasksQueryVariables>(GetMeterReadingsImportTasksDocument, options)
}
export function useGetMeterReadingsImportTasksSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetMeterReadingsImportTasksQuery, Types.GetMeterReadingsImportTasksQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetMeterReadingsImportTasksQuery, Types.GetMeterReadingsImportTasksQueryVariables>(GetMeterReadingsImportTasksDocument, options)
}
export type GetMeterReadingsImportTasksQueryHookResult = ReturnType<typeof useGetMeterReadingsImportTasksQuery>
export type GetMeterReadingsImportTasksLazyQueryHookResult = ReturnType<typeof useGetMeterReadingsImportTasksLazyQuery>
export type GetMeterReadingsImportTasksSuspenseQueryHookResult = ReturnType<typeof useGetMeterReadingsImportTasksSuspenseQuery>
export type GetMeterReadingsImportTasksQueryResult = Apollo.QueryResult<Types.GetMeterReadingsImportTasksQuery, Types.GetMeterReadingsImportTasksQueryVariables>
export const CreateMeterReadingsImportTaskDocument = gql`
    mutation createMeterReadingsImportTask($data: MeterReadingsImportTaskCreateInput!) {
  task: createMeterReadingsImportTask(data: $data) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    errorFile {
      publicUrl
    }
    errorMessage
    totalRecordsCount
    processedRecordsCount
    __typename
  }
}
    `
export type CreateMeterReadingsImportTaskMutationFn = Apollo.MutationFunction<Types.CreateMeterReadingsImportTaskMutation, Types.CreateMeterReadingsImportTaskMutationVariables>

/**
 * __useCreateMeterReadingsImportTaskMutation__
 *
 * To run a mutation, you first call `useCreateMeterReadingsImportTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMeterReadingsImportTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMeterReadingsImportTaskMutation, { data, loading, error }] = useCreateMeterReadingsImportTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMeterReadingsImportTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreateMeterReadingsImportTaskMutation, Types.CreateMeterReadingsImportTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateMeterReadingsImportTaskMutation, Types.CreateMeterReadingsImportTaskMutationVariables>(CreateMeterReadingsImportTaskDocument, options)
}
export type CreateMeterReadingsImportTaskMutationHookResult = ReturnType<typeof useCreateMeterReadingsImportTaskMutation>
export type CreateMeterReadingsImportTaskMutationResult = Apollo.MutationResult<Types.CreateMeterReadingsImportTaskMutation>
export type CreateMeterReadingsImportTaskMutationOptions = Apollo.BaseMutationOptions<Types.CreateMeterReadingsImportTaskMutation, Types.CreateMeterReadingsImportTaskMutationVariables>
export const UpdateMeterReadingsImportTaskDocument = gql`
    mutation updateMeterReadingsImportTask($id: ID!, $data: MeterReadingsImportTaskUpdateInput!) {
  task: updateMeterReadingsImportTask(data: $data, id: $id) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    errorFile {
      publicUrl
    }
    errorMessage
    totalRecordsCount
    processedRecordsCount
    __typename
  }
}
    `
export type UpdateMeterReadingsImportTaskMutationFn = Apollo.MutationFunction<Types.UpdateMeterReadingsImportTaskMutation, Types.UpdateMeterReadingsImportTaskMutationVariables>

/**
 * __useUpdateMeterReadingsImportTaskMutation__
 *
 * To run a mutation, you first call `useUpdateMeterReadingsImportTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeterReadingsImportTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeterReadingsImportTaskMutation, { data, loading, error }] = useUpdateMeterReadingsImportTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMeterReadingsImportTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateMeterReadingsImportTaskMutation, Types.UpdateMeterReadingsImportTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateMeterReadingsImportTaskMutation, Types.UpdateMeterReadingsImportTaskMutationVariables>(UpdateMeterReadingsImportTaskDocument, options)
}
export type UpdateMeterReadingsImportTaskMutationHookResult = ReturnType<typeof useUpdateMeterReadingsImportTaskMutation>
export type UpdateMeterReadingsImportTaskMutationResult = Apollo.MutationResult<Types.UpdateMeterReadingsImportTaskMutation>
export type UpdateMeterReadingsImportTaskMutationOptions = Apollo.BaseMutationOptions<Types.UpdateMeterReadingsImportTaskMutation, Types.UpdateMeterReadingsImportTaskMutationVariables>
export const GetNewsItemRecipientsExportTasksDocument = gql`
    query getNewsItemRecipientsExportTasks($where: NewsItemRecipientsExportTaskWhereInput!) {
  tasks: allNewsItemRecipientsExportTasks(where: $where) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    __typename
  }
}
    `

/**
 * __useGetNewsItemRecipientsExportTasksQuery__
 *
 * To run a query within a React component, call `useGetNewsItemRecipientsExportTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsItemRecipientsExportTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsItemRecipientsExportTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetNewsItemRecipientsExportTasksQuery (baseOptions: Apollo.QueryHookOptions<Types.GetNewsItemRecipientsExportTasksQuery, Types.GetNewsItemRecipientsExportTasksQueryVariables> & ({ variables: Types.GetNewsItemRecipientsExportTasksQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetNewsItemRecipientsExportTasksQuery, Types.GetNewsItemRecipientsExportTasksQueryVariables>(GetNewsItemRecipientsExportTasksDocument, options)
}
export function useGetNewsItemRecipientsExportTasksLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNewsItemRecipientsExportTasksQuery, Types.GetNewsItemRecipientsExportTasksQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetNewsItemRecipientsExportTasksQuery, Types.GetNewsItemRecipientsExportTasksQueryVariables>(GetNewsItemRecipientsExportTasksDocument, options)
}
export function useGetNewsItemRecipientsExportTasksSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetNewsItemRecipientsExportTasksQuery, Types.GetNewsItemRecipientsExportTasksQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetNewsItemRecipientsExportTasksQuery, Types.GetNewsItemRecipientsExportTasksQueryVariables>(GetNewsItemRecipientsExportTasksDocument, options)
}
export type GetNewsItemRecipientsExportTasksQueryHookResult = ReturnType<typeof useGetNewsItemRecipientsExportTasksQuery>
export type GetNewsItemRecipientsExportTasksLazyQueryHookResult = ReturnType<typeof useGetNewsItemRecipientsExportTasksLazyQuery>
export type GetNewsItemRecipientsExportTasksSuspenseQueryHookResult = ReturnType<typeof useGetNewsItemRecipientsExportTasksSuspenseQuery>
export type GetNewsItemRecipientsExportTasksQueryResult = Apollo.QueryResult<Types.GetNewsItemRecipientsExportTasksQuery, Types.GetNewsItemRecipientsExportTasksQueryVariables>
export const CreateNewsItemRecipientsExportTaskDocument = gql`
    mutation createNewsItemRecipientsExportTask($data: NewsItemRecipientsExportTaskCreateInput!) {
  task: createNewsItemRecipientsExportTask(data: $data) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    __typename
  }
}
    `
export type CreateNewsItemRecipientsExportTaskMutationFn = Apollo.MutationFunction<Types.CreateNewsItemRecipientsExportTaskMutation, Types.CreateNewsItemRecipientsExportTaskMutationVariables>

/**
 * __useCreateNewsItemRecipientsExportTaskMutation__
 *
 * To run a mutation, you first call `useCreateNewsItemRecipientsExportTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsItemRecipientsExportTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsItemRecipientsExportTaskMutation, { data, loading, error }] = useCreateNewsItemRecipientsExportTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNewsItemRecipientsExportTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreateNewsItemRecipientsExportTaskMutation, Types.CreateNewsItemRecipientsExportTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateNewsItemRecipientsExportTaskMutation, Types.CreateNewsItemRecipientsExportTaskMutationVariables>(CreateNewsItemRecipientsExportTaskDocument, options)
}
export type CreateNewsItemRecipientsExportTaskMutationHookResult = ReturnType<typeof useCreateNewsItemRecipientsExportTaskMutation>
export type CreateNewsItemRecipientsExportTaskMutationResult = Apollo.MutationResult<Types.CreateNewsItemRecipientsExportTaskMutation>
export type CreateNewsItemRecipientsExportTaskMutationOptions = Apollo.BaseMutationOptions<Types.CreateNewsItemRecipientsExportTaskMutation, Types.CreateNewsItemRecipientsExportTaskMutationVariables>
export const UpdateNewsItemRecipientsExportTaskDocument = gql`
    mutation updateNewsItemRecipientsExportTask($id: ID!, $data: NewsItemRecipientsExportTaskUpdateInput!) {
  task: updateNewsItemRecipientsExportTask(data: $data, id: $id) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    __typename
  }
}
    `
export type UpdateNewsItemRecipientsExportTaskMutationFn = Apollo.MutationFunction<Types.UpdateNewsItemRecipientsExportTaskMutation, Types.UpdateNewsItemRecipientsExportTaskMutationVariables>

/**
 * __useUpdateNewsItemRecipientsExportTaskMutation__
 *
 * To run a mutation, you first call `useUpdateNewsItemRecipientsExportTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewsItemRecipientsExportTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewsItemRecipientsExportTaskMutation, { data, loading, error }] = useUpdateNewsItemRecipientsExportTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateNewsItemRecipientsExportTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateNewsItemRecipientsExportTaskMutation, Types.UpdateNewsItemRecipientsExportTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateNewsItemRecipientsExportTaskMutation, Types.UpdateNewsItemRecipientsExportTaskMutationVariables>(UpdateNewsItemRecipientsExportTaskDocument, options)
}
export type UpdateNewsItemRecipientsExportTaskMutationHookResult = ReturnType<typeof useUpdateNewsItemRecipientsExportTaskMutation>
export type UpdateNewsItemRecipientsExportTaskMutationResult = Apollo.MutationResult<Types.UpdateNewsItemRecipientsExportTaskMutation>
export type UpdateNewsItemRecipientsExportTaskMutationOptions = Apollo.BaseMutationOptions<Types.UpdateNewsItemRecipientsExportTaskMutation, Types.UpdateNewsItemRecipientsExportTaskMutationVariables>
export const GetActiveOrganizationEmployeeDocument = gql`
    query getActiveOrganizationEmployee($userId: ID!, $employeeId: ID) {
  employees: allOrganizationEmployees(
    where: {id: $employeeId, organization: {type_in: [MANAGING_COMPANY, SERVICE_PROVIDER]}, user: {id: $userId, type: staff}, isAccepted: true, isBlocked: false, isRejected: false}
    first: 1
    skip: 0
    sortBy: [createdAt_DESC]
  ) {
    id
    name
    email
    phone
    hasAllSpecializations
    isRejected
    isAccepted
    isBlocked
    position
    organization {
      id
      country
      name
      type
      tin
      features
      statusTransitions
      importId
      importRemoteSystem
    }
    role {
      id
      name
      nameNonLocalized
      isEditable
      isDefault
      statusTransitions
      ticketVisibilityType
      canReadAnalytics
      canManageOrganization
      canManageCallRecords
      canDownloadCallRecords
      canReadEmployees
      canManageEmployees
      canInviteNewOrganizationEmployees
      canManageRoles
      canManageTicketPropertyHints
      canManageIntegrations
      canImportBillingReceipts
      canReadBillingReceipts
      canReadPayments
      canManageProperties
      canReadProperties
      canReadDocuments
      canManageDocuments
      canReadTickets
      canManageTickets
      canReadContacts
      canManageContacts
      canManageContactRoles
      canManageTicketComments
      canManagePropertyScopes
      canShareTickets
      canBeAssignedAsResponsible
      canBeAssignedAsExecutor
      canManageMeters
      canManageMeterReadings
      canManageBankAccounts
      canManageBankContractorAccounts
      canManageBankIntegrationAccountContexts
      canManageBankIntegrationOrganizationContexts
      canManageBankTransactions
      canManageBankAccountReports
      canManageBankAccountReportTasks
      canManageBankAccountReports
      canReadIncidents
      canManageIncidents
      canReadNewsItems
      canManageNewsItems
      canManageNewsItemTemplates
      canManageMobileFeatureConfigs
      canManageB2BApps
      canReadMeters
      canReadSettings
      canReadExternalReports
      canReadServices
      canReadCallRecords
      canReadInvoices
      canManageInvoices
      canReadMarketItems
      canManageMarketItems
      canManageMarketItemPrices
      canReadMarketItemPrices
      canReadMarketPriceScopes
      canManageMarketPriceScopes
      canReadMarketplace
      canManageMarketplace
      canReadPaymentsWithInvoices
      canReadTour
      canManageTour
      canReadMarketSetting
      canManageMarketSetting
      canManageTicketAutoAssignments
    }
  }
}
    `

/**
 * __useGetActiveOrganizationEmployeeQuery__
 *
 * To run a query within a React component, call `useGetActiveOrganizationEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveOrganizationEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveOrganizationEmployeeQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetActiveOrganizationEmployeeQuery (baseOptions: Apollo.QueryHookOptions<Types.GetActiveOrganizationEmployeeQuery, Types.GetActiveOrganizationEmployeeQueryVariables> & ({ variables: Types.GetActiveOrganizationEmployeeQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetActiveOrganizationEmployeeQuery, Types.GetActiveOrganizationEmployeeQueryVariables>(GetActiveOrganizationEmployeeDocument, options)
}
export function useGetActiveOrganizationEmployeeLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetActiveOrganizationEmployeeQuery, Types.GetActiveOrganizationEmployeeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetActiveOrganizationEmployeeQuery, Types.GetActiveOrganizationEmployeeQueryVariables>(GetActiveOrganizationEmployeeDocument, options)
}
export function useGetActiveOrganizationEmployeeSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetActiveOrganizationEmployeeQuery, Types.GetActiveOrganizationEmployeeQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetActiveOrganizationEmployeeQuery, Types.GetActiveOrganizationEmployeeQueryVariables>(GetActiveOrganizationEmployeeDocument, options)
}
export type GetActiveOrganizationEmployeeQueryHookResult = ReturnType<typeof useGetActiveOrganizationEmployeeQuery>
export type GetActiveOrganizationEmployeeLazyQueryHookResult = ReturnType<typeof useGetActiveOrganizationEmployeeLazyQuery>
export type GetActiveOrganizationEmployeeSuspenseQueryHookResult = ReturnType<typeof useGetActiveOrganizationEmployeeSuspenseQuery>
export type GetActiveOrganizationEmployeeQueryResult = Apollo.QueryResult<Types.GetActiveOrganizationEmployeeQuery, Types.GetActiveOrganizationEmployeeQueryVariables>
export const GetActualOrganizationEmployeesDocument = gql`
    query getActualOrganizationEmployees($userId: ID!) {
  actualEmployees: allOrganizationEmployees(
    where: {user: {id: $userId}, isAccepted: true, isRejected: false, isBlocked: false, organization: {type_in: [MANAGING_COMPANY, SERVICE_PROVIDER]}}
    first: 500
  ) {
    id
    organization {
      id
      name
      importId
      importRemoteSystem
    }
  }
}
    `

/**
 * __useGetActualOrganizationEmployeesQuery__
 *
 * To run a query within a React component, call `useGetActualOrganizationEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActualOrganizationEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActualOrganizationEmployeesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetActualOrganizationEmployeesQuery (baseOptions: Apollo.QueryHookOptions<Types.GetActualOrganizationEmployeesQuery, Types.GetActualOrganizationEmployeesQueryVariables> & ({ variables: Types.GetActualOrganizationEmployeesQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetActualOrganizationEmployeesQuery, Types.GetActualOrganizationEmployeesQueryVariables>(GetActualOrganizationEmployeesDocument, options)
}
export function useGetActualOrganizationEmployeesLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetActualOrganizationEmployeesQuery, Types.GetActualOrganizationEmployeesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetActualOrganizationEmployeesQuery, Types.GetActualOrganizationEmployeesQueryVariables>(GetActualOrganizationEmployeesDocument, options)
}
export function useGetActualOrganizationEmployeesSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetActualOrganizationEmployeesQuery, Types.GetActualOrganizationEmployeesQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetActualOrganizationEmployeesQuery, Types.GetActualOrganizationEmployeesQueryVariables>(GetActualOrganizationEmployeesDocument, options)
}
export type GetActualOrganizationEmployeesQueryHookResult = ReturnType<typeof useGetActualOrganizationEmployeesQuery>
export type GetActualOrganizationEmployeesLazyQueryHookResult = ReturnType<typeof useGetActualOrganizationEmployeesLazyQuery>
export type GetActualOrganizationEmployeesSuspenseQueryHookResult = ReturnType<typeof useGetActualOrganizationEmployeesSuspenseQuery>
export type GetActualOrganizationEmployeesQueryResult = Apollo.QueryResult<Types.GetActualOrganizationEmployeesQuery, Types.GetActualOrganizationEmployeesQueryVariables>
export const GetEmployeeInvitesCountDocument = gql`
    query getEmployeeInvitesCount($userId: ID!) {
  meta: _allOrganizationEmployeesMeta(
    where: {user: {id: $userId}, isAccepted: false, isRejected: false, isBlocked: false, organization: {type_in: [MANAGING_COMPANY, SERVICE_PROVIDER]}}
  ) {
    count
  }
}
    `

/**
 * __useGetEmployeeInvitesCountQuery__
 *
 * To run a query within a React component, call `useGetEmployeeInvitesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeInvitesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeInvitesCountQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetEmployeeInvitesCountQuery (baseOptions: Apollo.QueryHookOptions<Types.GetEmployeeInvitesCountQuery, Types.GetEmployeeInvitesCountQueryVariables> & ({ variables: Types.GetEmployeeInvitesCountQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetEmployeeInvitesCountQuery, Types.GetEmployeeInvitesCountQueryVariables>(GetEmployeeInvitesCountDocument, options)
}
export function useGetEmployeeInvitesCountLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmployeeInvitesCountQuery, Types.GetEmployeeInvitesCountQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetEmployeeInvitesCountQuery, Types.GetEmployeeInvitesCountQueryVariables>(GetEmployeeInvitesCountDocument, options)
}
export function useGetEmployeeInvitesCountSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetEmployeeInvitesCountQuery, Types.GetEmployeeInvitesCountQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetEmployeeInvitesCountQuery, Types.GetEmployeeInvitesCountQueryVariables>(GetEmployeeInvitesCountDocument, options)
}
export type GetEmployeeInvitesCountQueryHookResult = ReturnType<typeof useGetEmployeeInvitesCountQuery>
export type GetEmployeeInvitesCountLazyQueryHookResult = ReturnType<typeof useGetEmployeeInvitesCountLazyQuery>
export type GetEmployeeInvitesCountSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeInvitesCountSuspenseQuery>
export type GetEmployeeInvitesCountQueryResult = Apollo.QueryResult<Types.GetEmployeeInvitesCountQuery, Types.GetEmployeeInvitesCountQueryVariables>
export const GetContactEditorOrganizationEmployeesDocument = gql`
    query getContactEditorOrganizationEmployees($where: OrganizationEmployeeWhereInput) {
  employees: allOrganizationEmployees(where: $where, first: 100) {
    id
    name
    phone
  }
}
    `

/**
 * __useGetContactEditorOrganizationEmployeesQuery__
 *
 * To run a query within a React component, call `useGetContactEditorOrganizationEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactEditorOrganizationEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactEditorOrganizationEmployeesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetContactEditorOrganizationEmployeesQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetContactEditorOrganizationEmployeesQuery, Types.GetContactEditorOrganizationEmployeesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetContactEditorOrganizationEmployeesQuery, Types.GetContactEditorOrganizationEmployeesQueryVariables>(GetContactEditorOrganizationEmployeesDocument, options)
}
export function useGetContactEditorOrganizationEmployeesLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContactEditorOrganizationEmployeesQuery, Types.GetContactEditorOrganizationEmployeesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetContactEditorOrganizationEmployeesQuery, Types.GetContactEditorOrganizationEmployeesQueryVariables>(GetContactEditorOrganizationEmployeesDocument, options)
}
export function useGetContactEditorOrganizationEmployeesSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetContactEditorOrganizationEmployeesQuery, Types.GetContactEditorOrganizationEmployeesQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetContactEditorOrganizationEmployeesQuery, Types.GetContactEditorOrganizationEmployeesQueryVariables>(GetContactEditorOrganizationEmployeesDocument, options)
}
export type GetContactEditorOrganizationEmployeesQueryHookResult = ReturnType<typeof useGetContactEditorOrganizationEmployeesQuery>
export type GetContactEditorOrganizationEmployeesLazyQueryHookResult = ReturnType<typeof useGetContactEditorOrganizationEmployeesLazyQuery>
export type GetContactEditorOrganizationEmployeesSuspenseQueryHookResult = ReturnType<typeof useGetContactEditorOrganizationEmployeesSuspenseQuery>
export type GetContactEditorOrganizationEmployeesQueryResult = Apollo.QueryResult<Types.GetContactEditorOrganizationEmployeesQuery, Types.GetContactEditorOrganizationEmployeesQueryVariables>
export const GetOrganizationEmployeeWithTicketOrganizationDocument = gql`
    query getOrganizationEmployeeWithTicketOrganization($userId: ID!, $organizationId: ID!) {
  employees: allOrganizationEmployees(
    where: {user: {id: $userId}, organization: {id: $organizationId}}
    first: 1
  ) {
    id
  }
}
    `

/**
 * __useGetOrganizationEmployeeWithTicketOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationEmployeeWithTicketOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationEmployeeWithTicketOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationEmployeeWithTicketOrganizationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationEmployeeWithTicketOrganizationQuery (baseOptions: Apollo.QueryHookOptions<Types.GetOrganizationEmployeeWithTicketOrganizationQuery, Types.GetOrganizationEmployeeWithTicketOrganizationQueryVariables> & ({ variables: Types.GetOrganizationEmployeeWithTicketOrganizationQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetOrganizationEmployeeWithTicketOrganizationQuery, Types.GetOrganizationEmployeeWithTicketOrganizationQueryVariables>(GetOrganizationEmployeeWithTicketOrganizationDocument, options)
}
export function useGetOrganizationEmployeeWithTicketOrganizationLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrganizationEmployeeWithTicketOrganizationQuery, Types.GetOrganizationEmployeeWithTicketOrganizationQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetOrganizationEmployeeWithTicketOrganizationQuery, Types.GetOrganizationEmployeeWithTicketOrganizationQueryVariables>(GetOrganizationEmployeeWithTicketOrganizationDocument, options)
}
export function useGetOrganizationEmployeeWithTicketOrganizationSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetOrganizationEmployeeWithTicketOrganizationQuery, Types.GetOrganizationEmployeeWithTicketOrganizationQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetOrganizationEmployeeWithTicketOrganizationQuery, Types.GetOrganizationEmployeeWithTicketOrganizationQueryVariables>(GetOrganizationEmployeeWithTicketOrganizationDocument, options)
}
export type GetOrganizationEmployeeWithTicketOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationEmployeeWithTicketOrganizationQuery>
export type GetOrganizationEmployeeWithTicketOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationEmployeeWithTicketOrganizationLazyQuery>
export type GetOrganizationEmployeeWithTicketOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationEmployeeWithTicketOrganizationSuspenseQuery>
export type GetOrganizationEmployeeWithTicketOrganizationQueryResult = Apollo.QueryResult<Types.GetOrganizationEmployeeWithTicketOrganizationQuery, Types.GetOrganizationEmployeeWithTicketOrganizationQueryVariables>
export const GetPropertyWithMapByIdDocument = gql`
    query getPropertyWithMapById($id: ID!) {
  property: allProperties(where: {id: $id}) {
    id
    organization {
      id
      name
    }
    map {
      sections {
        ...SectionOrParkingInfo
      }
      parking {
        ...SectionOrParkingInfo
      }
      dv
    }
  }
}
    ${SectionOrParkingInfoFragmentDoc}`

/**
 * __useGetPropertyWithMapByIdQuery__
 *
 * To run a query within a React component, call `useGetPropertyWithMapByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyWithMapByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyWithMapByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyWithMapByIdQuery (baseOptions: Apollo.QueryHookOptions<Types.GetPropertyWithMapByIdQuery, Types.GetPropertyWithMapByIdQueryVariables> & ({ variables: Types.GetPropertyWithMapByIdQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetPropertyWithMapByIdQuery, Types.GetPropertyWithMapByIdQueryVariables>(GetPropertyWithMapByIdDocument, options)
}
export function useGetPropertyWithMapByIdLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPropertyWithMapByIdQuery, Types.GetPropertyWithMapByIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetPropertyWithMapByIdQuery, Types.GetPropertyWithMapByIdQueryVariables>(GetPropertyWithMapByIdDocument, options)
}
export function useGetPropertyWithMapByIdSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetPropertyWithMapByIdQuery, Types.GetPropertyWithMapByIdQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetPropertyWithMapByIdQuery, Types.GetPropertyWithMapByIdQueryVariables>(GetPropertyWithMapByIdDocument, options)
}
export type GetPropertyWithMapByIdQueryHookResult = ReturnType<typeof useGetPropertyWithMapByIdQuery>
export type GetPropertyWithMapByIdLazyQueryHookResult = ReturnType<typeof useGetPropertyWithMapByIdLazyQuery>
export type GetPropertyWithMapByIdSuspenseQueryHookResult = ReturnType<typeof useGetPropertyWithMapByIdSuspenseQuery>
export type GetPropertyWithMapByIdQueryResult = Apollo.QueryResult<Types.GetPropertyWithMapByIdQuery, Types.GetPropertyWithMapByIdQueryVariables>
export const GetPropertyByIdDocument = gql`
    query getPropertyById($id: ID!) {
  properties: allProperties(where: {id: $id}, first: 1) {
    id
    address
    organization {
      id
    }
    map {
      sections {
        ...SectionOrParkingInfo
      }
      parking {
        ...SectionOrParkingInfo
      }
    }
  }
}
    ${SectionOrParkingInfoFragmentDoc}`

/**
 * __useGetPropertyByIdQuery__
 *
 * To run a query within a React component, call `useGetPropertyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPropertyByIdQuery (baseOptions: Apollo.QueryHookOptions<Types.GetPropertyByIdQuery, Types.GetPropertyByIdQueryVariables> & ({ variables: Types.GetPropertyByIdQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetPropertyByIdQuery, Types.GetPropertyByIdQueryVariables>(GetPropertyByIdDocument, options)
}
export function useGetPropertyByIdLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPropertyByIdQuery, Types.GetPropertyByIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetPropertyByIdQuery, Types.GetPropertyByIdQueryVariables>(GetPropertyByIdDocument, options)
}
export function useGetPropertyByIdSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetPropertyByIdQuery, Types.GetPropertyByIdQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetPropertyByIdQuery, Types.GetPropertyByIdQueryVariables>(GetPropertyByIdDocument, options)
}
export type GetPropertyByIdQueryHookResult = ReturnType<typeof useGetPropertyByIdQuery>
export type GetPropertyByIdLazyQueryHookResult = ReturnType<typeof useGetPropertyByIdLazyQuery>
export type GetPropertyByIdSuspenseQueryHookResult = ReturnType<typeof useGetPropertyByIdSuspenseQuery>
export type GetPropertyByIdQueryResult = Apollo.QueryResult<Types.GetPropertyByIdQuery, Types.GetPropertyByIdQueryVariables>
export const GetServiceSubscriptionDocument = gql`
    query getServiceSubscription($organizationId: ID!) {
  subscriptions: allServiceSubscriptions(
    where: {organization: {id: $organizationId}}
    first: 1
    sortBy: [startAt_DESC]
  ) {
    id
    isTrial
    finishAt
    type
  }
}
    `

/**
 * __useGetServiceSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetServiceSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceSubscriptionQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetServiceSubscriptionQuery (baseOptions: Apollo.QueryHookOptions<Types.GetServiceSubscriptionQuery, Types.GetServiceSubscriptionQueryVariables> & ({ variables: Types.GetServiceSubscriptionQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetServiceSubscriptionQuery, Types.GetServiceSubscriptionQueryVariables>(GetServiceSubscriptionDocument, options)
}
export function useGetServiceSubscriptionLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetServiceSubscriptionQuery, Types.GetServiceSubscriptionQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetServiceSubscriptionQuery, Types.GetServiceSubscriptionQueryVariables>(GetServiceSubscriptionDocument, options)
}
export function useGetServiceSubscriptionSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetServiceSubscriptionQuery, Types.GetServiceSubscriptionQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetServiceSubscriptionQuery, Types.GetServiceSubscriptionQueryVariables>(GetServiceSubscriptionDocument, options)
}
export type GetServiceSubscriptionQueryHookResult = ReturnType<typeof useGetServiceSubscriptionQuery>
export type GetServiceSubscriptionLazyQueryHookResult = ReturnType<typeof useGetServiceSubscriptionLazyQuery>
export type GetServiceSubscriptionSuspenseQueryHookResult = ReturnType<typeof useGetServiceSubscriptionSuspenseQuery>
export type GetServiceSubscriptionQueryResult = Apollo.QueryResult<Types.GetServiceSubscriptionQuery, Types.GetServiceSubscriptionQueryVariables>
export const GetTrialServiceSubscriptionDocument = gql`
    query getTrialServiceSubscription($organizationId: ID!, $finishAtLte: String, $finishAtGte: String) {
  subscriptions: allServiceSubscriptions(
    where: {organization: {id: $organizationId}, type: sbbol, isTrial: true, finishAt_lte: $finishAtLte, finishAt_gte: $finishAtGte}
    first: 1
    sortBy: [startAt_DESC]
  ) {
    id
    finishAt
  }
}
    `

/**
 * __useGetTrialServiceSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetTrialServiceSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrialServiceSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrialServiceSubscriptionQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      finishAtLte: // value for 'finishAtLte'
 *      finishAtGte: // value for 'finishAtGte'
 *   },
 * });
 */
export function useGetTrialServiceSubscriptionQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTrialServiceSubscriptionQuery, Types.GetTrialServiceSubscriptionQueryVariables> & ({ variables: Types.GetTrialServiceSubscriptionQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTrialServiceSubscriptionQuery, Types.GetTrialServiceSubscriptionQueryVariables>(GetTrialServiceSubscriptionDocument, options)
}
export function useGetTrialServiceSubscriptionLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTrialServiceSubscriptionQuery, Types.GetTrialServiceSubscriptionQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTrialServiceSubscriptionQuery, Types.GetTrialServiceSubscriptionQueryVariables>(GetTrialServiceSubscriptionDocument, options)
}
export function useGetTrialServiceSubscriptionSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTrialServiceSubscriptionQuery, Types.GetTrialServiceSubscriptionQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTrialServiceSubscriptionQuery, Types.GetTrialServiceSubscriptionQueryVariables>(GetTrialServiceSubscriptionDocument, options)
}
export type GetTrialServiceSubscriptionQueryHookResult = ReturnType<typeof useGetTrialServiceSubscriptionQuery>
export type GetTrialServiceSubscriptionLazyQueryHookResult = ReturnType<typeof useGetTrialServiceSubscriptionLazyQuery>
export type GetTrialServiceSubscriptionSuspenseQueryHookResult = ReturnType<typeof useGetTrialServiceSubscriptionSuspenseQuery>
export type GetTrialServiceSubscriptionQueryResult = Apollo.QueryResult<Types.GetTrialServiceSubscriptionQuery, Types.GetTrialServiceSubscriptionQueryVariables>
export const GetCallRecordFragmentExistenceDocument = gql`
    query getCallRecordFragmentExistence($organizationId: ID!) {
  callRecordFragments: allCallRecordFragments(
    where: {organization: {id: $organizationId}}
    first: 1
  ) {
    id
  }
}
    `

/**
 * __useGetCallRecordFragmentExistenceQuery__
 *
 * To run a query within a React component, call `useGetCallRecordFragmentExistenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCallRecordFragmentExistenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCallRecordFragmentExistenceQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetCallRecordFragmentExistenceQuery (baseOptions: Apollo.QueryHookOptions<Types.GetCallRecordFragmentExistenceQuery, Types.GetCallRecordFragmentExistenceQueryVariables> & ({ variables: Types.GetCallRecordFragmentExistenceQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetCallRecordFragmentExistenceQuery, Types.GetCallRecordFragmentExistenceQueryVariables>(GetCallRecordFragmentExistenceDocument, options)
}
export function useGetCallRecordFragmentExistenceLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCallRecordFragmentExistenceQuery, Types.GetCallRecordFragmentExistenceQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetCallRecordFragmentExistenceQuery, Types.GetCallRecordFragmentExistenceQueryVariables>(GetCallRecordFragmentExistenceDocument, options)
}
export function useGetCallRecordFragmentExistenceSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetCallRecordFragmentExistenceQuery, Types.GetCallRecordFragmentExistenceQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetCallRecordFragmentExistenceQuery, Types.GetCallRecordFragmentExistenceQueryVariables>(GetCallRecordFragmentExistenceDocument, options)
}
export type GetCallRecordFragmentExistenceQueryHookResult = ReturnType<typeof useGetCallRecordFragmentExistenceQuery>
export type GetCallRecordFragmentExistenceLazyQueryHookResult = ReturnType<typeof useGetCallRecordFragmentExistenceLazyQuery>
export type GetCallRecordFragmentExistenceSuspenseQueryHookResult = ReturnType<typeof useGetCallRecordFragmentExistenceSuspenseQuery>
export type GetCallRecordFragmentExistenceQueryResult = Apollo.QueryResult<Types.GetCallRecordFragmentExistenceQuery, Types.GetCallRecordFragmentExistenceQueryVariables>
export const GetTicketCallRecordsFragmentsDocument = gql`
    query getTicketCallRecordsFragments($ticketId: ID!) {
  callRecordFragments: allCallRecordFragments(
    where: {ticket: {id: $ticketId}}
    sortBy: [createdAt_DESC]
  ) {
    id
    callRecord {
      id
      isIncomingCall
      callerPhone
      destCallerPhone
      startedAt
      file {
        publicUrl
        id
      }
    }
  }
}
    `

/**
 * __useGetTicketCallRecordsFragmentsQuery__
 *
 * To run a query within a React component, call `useGetTicketCallRecordsFragmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketCallRecordsFragmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketCallRecordsFragmentsQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGetTicketCallRecordsFragmentsQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketCallRecordsFragmentsQuery, Types.GetTicketCallRecordsFragmentsQueryVariables> & ({ variables: Types.GetTicketCallRecordsFragmentsQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketCallRecordsFragmentsQuery, Types.GetTicketCallRecordsFragmentsQueryVariables>(GetTicketCallRecordsFragmentsDocument, options)
}
export function useGetTicketCallRecordsFragmentsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketCallRecordsFragmentsQuery, Types.GetTicketCallRecordsFragmentsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketCallRecordsFragmentsQuery, Types.GetTicketCallRecordsFragmentsQueryVariables>(GetTicketCallRecordsFragmentsDocument, options)
}
export function useGetTicketCallRecordsFragmentsSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketCallRecordsFragmentsQuery, Types.GetTicketCallRecordsFragmentsQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketCallRecordsFragmentsQuery, Types.GetTicketCallRecordsFragmentsQueryVariables>(GetTicketCallRecordsFragmentsDocument, options)
}
export type GetTicketCallRecordsFragmentsQueryHookResult = ReturnType<typeof useGetTicketCallRecordsFragmentsQuery>
export type GetTicketCallRecordsFragmentsLazyQueryHookResult = ReturnType<typeof useGetTicketCallRecordsFragmentsLazyQuery>
export type GetTicketCallRecordsFragmentsSuspenseQueryHookResult = ReturnType<typeof useGetTicketCallRecordsFragmentsSuspenseQuery>
export type GetTicketCallRecordsFragmentsQueryResult = Apollo.QueryResult<Types.GetTicketCallRecordsFragmentsQuery, Types.GetTicketCallRecordsFragmentsQueryVariables>
export const GetIncidentsDocument = gql`
    query getIncidents($where: IncidentWhereInput, $sortBy: [SortIncidentsBy!]) {
  incidents: allIncidents(where: $where, sortBy: $sortBy) {
    id
    details
    workStart
    workFinish
    status
  }
}
    `

/**
 * __useGetIncidentsQuery__
 *
 * To run a query within a React component, call `useGetIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncidentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetIncidentsQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetIncidentsQuery, Types.GetIncidentsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetIncidentsQuery, Types.GetIncidentsQueryVariables>(GetIncidentsDocument, options)
}
export function useGetIncidentsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetIncidentsQuery, Types.GetIncidentsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetIncidentsQuery, Types.GetIncidentsQueryVariables>(GetIncidentsDocument, options)
}
export function useGetIncidentsSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetIncidentsQuery, Types.GetIncidentsQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetIncidentsQuery, Types.GetIncidentsQueryVariables>(GetIncidentsDocument, options)
}
export type GetIncidentsQueryHookResult = ReturnType<typeof useGetIncidentsQuery>
export type GetIncidentsLazyQueryHookResult = ReturnType<typeof useGetIncidentsLazyQuery>
export type GetIncidentsSuspenseQueryHookResult = ReturnType<typeof useGetIncidentsSuspenseQuery>
export type GetIncidentsQueryResult = Apollo.QueryResult<Types.GetIncidentsQuery, Types.GetIncidentsQueryVariables>
export const GetIncidentClassifierIncidentDocument = gql`
    query getIncidentClassifierIncident($where: IncidentClassifierIncidentWhereInput) {
  incidentClassifierIncident: allIncidentClassifierIncidents(where: $where) {
    id
    incident {
      id
    }
  }
}
    `

/**
 * __useGetIncidentClassifierIncidentQuery__
 *
 * To run a query within a React component, call `useGetIncidentClassifierIncidentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncidentClassifierIncidentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncidentClassifierIncidentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetIncidentClassifierIncidentQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetIncidentClassifierIncidentQuery, Types.GetIncidentClassifierIncidentQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetIncidentClassifierIncidentQuery, Types.GetIncidentClassifierIncidentQueryVariables>(GetIncidentClassifierIncidentDocument, options)
}
export function useGetIncidentClassifierIncidentLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetIncidentClassifierIncidentQuery, Types.GetIncidentClassifierIncidentQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetIncidentClassifierIncidentQuery, Types.GetIncidentClassifierIncidentQueryVariables>(GetIncidentClassifierIncidentDocument, options)
}
export function useGetIncidentClassifierIncidentSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetIncidentClassifierIncidentQuery, Types.GetIncidentClassifierIncidentQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetIncidentClassifierIncidentQuery, Types.GetIncidentClassifierIncidentQueryVariables>(GetIncidentClassifierIncidentDocument, options)
}
export type GetIncidentClassifierIncidentQueryHookResult = ReturnType<typeof useGetIncidentClassifierIncidentQuery>
export type GetIncidentClassifierIncidentLazyQueryHookResult = ReturnType<typeof useGetIncidentClassifierIncidentLazyQuery>
export type GetIncidentClassifierIncidentSuspenseQueryHookResult = ReturnType<typeof useGetIncidentClassifierIncidentSuspenseQuery>
export type GetIncidentClassifierIncidentQueryResult = Apollo.QueryResult<Types.GetIncidentClassifierIncidentQuery, Types.GetIncidentClassifierIncidentQueryVariables>
export const GetIncidentExportTasksDocument = gql`
    query getIncidentExportTasks($where: IncidentExportTaskWhereInput!) {
  tasks: allIncidentExportTasks(where: $where) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
}
    `

/**
 * __useGetIncidentExportTasksQuery__
 *
 * To run a query within a React component, call `useGetIncidentExportTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncidentExportTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncidentExportTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetIncidentExportTasksQuery (baseOptions: Apollo.QueryHookOptions<Types.GetIncidentExportTasksQuery, Types.GetIncidentExportTasksQueryVariables> & ({ variables: Types.GetIncidentExportTasksQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetIncidentExportTasksQuery, Types.GetIncidentExportTasksQueryVariables>(GetIncidentExportTasksDocument, options)
}
export function useGetIncidentExportTasksLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetIncidentExportTasksQuery, Types.GetIncidentExportTasksQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetIncidentExportTasksQuery, Types.GetIncidentExportTasksQueryVariables>(GetIncidentExportTasksDocument, options)
}
export function useGetIncidentExportTasksSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetIncidentExportTasksQuery, Types.GetIncidentExportTasksQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetIncidentExportTasksQuery, Types.GetIncidentExportTasksQueryVariables>(GetIncidentExportTasksDocument, options)
}
export type GetIncidentExportTasksQueryHookResult = ReturnType<typeof useGetIncidentExportTasksQuery>
export type GetIncidentExportTasksLazyQueryHookResult = ReturnType<typeof useGetIncidentExportTasksLazyQuery>
export type GetIncidentExportTasksSuspenseQueryHookResult = ReturnType<typeof useGetIncidentExportTasksSuspenseQuery>
export type GetIncidentExportTasksQueryResult = Apollo.QueryResult<Types.GetIncidentExportTasksQuery, Types.GetIncidentExportTasksQueryVariables>
export const CreateIncidentExportTaskDocument = gql`
    mutation createIncidentExportTask($data: IncidentExportTaskCreateInput!) {
  task: createIncidentExportTask(data: $data) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
}
    `
export type CreateIncidentExportTaskMutationFn = Apollo.MutationFunction<Types.CreateIncidentExportTaskMutation, Types.CreateIncidentExportTaskMutationVariables>

/**
 * __useCreateIncidentExportTaskMutation__
 *
 * To run a mutation, you first call `useCreateIncidentExportTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIncidentExportTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIncidentExportTaskMutation, { data, loading, error }] = useCreateIncidentExportTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateIncidentExportTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreateIncidentExportTaskMutation, Types.CreateIncidentExportTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateIncidentExportTaskMutation, Types.CreateIncidentExportTaskMutationVariables>(CreateIncidentExportTaskDocument, options)
}
export type CreateIncidentExportTaskMutationHookResult = ReturnType<typeof useCreateIncidentExportTaskMutation>
export type CreateIncidentExportTaskMutationResult = Apollo.MutationResult<Types.CreateIncidentExportTaskMutation>
export type CreateIncidentExportTaskMutationOptions = Apollo.BaseMutationOptions<Types.CreateIncidentExportTaskMutation, Types.CreateIncidentExportTaskMutationVariables>
export const UpdateIncidentExportTaskDocument = gql`
    mutation updateIncidentExportTask($id: ID!, $data: IncidentExportTaskUpdateInput!) {
  task: updateIncidentExportTask(data: $data, id: $id) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
}
    `
export type UpdateIncidentExportTaskMutationFn = Apollo.MutationFunction<Types.UpdateIncidentExportTaskMutation, Types.UpdateIncidentExportTaskMutationVariables>

/**
 * __useUpdateIncidentExportTaskMutation__
 *
 * To run a mutation, you first call `useUpdateIncidentExportTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIncidentExportTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncidentExportTaskMutation, { data, loading, error }] = useUpdateIncidentExportTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateIncidentExportTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateIncidentExportTaskMutation, Types.UpdateIncidentExportTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateIncidentExportTaskMutation, Types.UpdateIncidentExportTaskMutationVariables>(UpdateIncidentExportTaskDocument, options)
}
export type UpdateIncidentExportTaskMutationHookResult = ReturnType<typeof useUpdateIncidentExportTaskMutation>
export type UpdateIncidentExportTaskMutationResult = Apollo.MutationResult<Types.UpdateIncidentExportTaskMutation>
export type UpdateIncidentExportTaskMutationOptions = Apollo.BaseMutationOptions<Types.UpdateIncidentExportTaskMutation, Types.UpdateIncidentExportTaskMutationVariables>
export const GetIncidentPropertiesDocument = gql`
    query getIncidentProperties($where: IncidentPropertyWhereInput) {
  incidentProperties: allIncidentProperties(where: $where) {
    id
    incident {
      id
    }
  }
}
    `

/**
 * __useGetIncidentPropertiesQuery__
 *
 * To run a query within a React component, call `useGetIncidentPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncidentPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncidentPropertiesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetIncidentPropertiesQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetIncidentPropertiesQuery, Types.GetIncidentPropertiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetIncidentPropertiesQuery, Types.GetIncidentPropertiesQueryVariables>(GetIncidentPropertiesDocument, options)
}
export function useGetIncidentPropertiesLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetIncidentPropertiesQuery, Types.GetIncidentPropertiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetIncidentPropertiesQuery, Types.GetIncidentPropertiesQueryVariables>(GetIncidentPropertiesDocument, options)
}
export function useGetIncidentPropertiesSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetIncidentPropertiesQuery, Types.GetIncidentPropertiesQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetIncidentPropertiesQuery, Types.GetIncidentPropertiesQueryVariables>(GetIncidentPropertiesDocument, options)
}
export type GetIncidentPropertiesQueryHookResult = ReturnType<typeof useGetIncidentPropertiesQuery>
export type GetIncidentPropertiesLazyQueryHookResult = ReturnType<typeof useGetIncidentPropertiesLazyQuery>
export type GetIncidentPropertiesSuspenseQueryHookResult = ReturnType<typeof useGetIncidentPropertiesSuspenseQuery>
export type GetIncidentPropertiesQueryResult = Apollo.QueryResult<Types.GetIncidentPropertiesQuery, Types.GetIncidentPropertiesQueryVariables>
export const GetTicketsDocument = gql`
    query getTickets($where: TicketWhereInput!, $sortBy: [SortTicketsBy!], $first: Int, $skip: Int) {
  tickets: allTickets(where: $where, sortBy: $sortBy, first: $first, skip: $skip) {
    id
    number
    createdAt
    isEmergency
    isPayable
    isWarranty
    statusReopenedCounter
    statusUpdatedAt
    completedAt
    deadline
    lastResidentCommentAt
    lastCommentWithResidentTypeAt
    propertyAddressMeta {
      ...AddressMetaForTableAddress
    }
    propertyAddress
    sectionType
    sectionName
    floorName
    unitType
    unitName
    details
    clientName
    clientPhone
    classifier {
      id
      category {
        id
        name
      }
      place {
        id
        name
      }
    }
    contact {
      id
      name
    }
    executor {
      id
      name
    }
    assignee {
      id
      name
    }
    organization {
      id
      name
    }
    property {
      id
      deletedAt
      address
      addressMeta {
        ...AddressMetaForTableAddress
      }
    }
    status {
      id
      type
      name
      colors {
        primary
        secondary
      }
    }
    createdBy {
      id
      name
    }
    source {
      id
      name
    }
  }
  meta: _allTicketsMeta(where: $where) {
    count
  }
}
    ${AddressMetaForTableAddressFragmentDoc}`

/**
 * __useGetTicketsQuery__
 *
 * To run a query within a React component, call `useGetTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      sortBy: // value for 'sortBy'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetTicketsQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketsQuery, Types.GetTicketsQueryVariables> & ({ variables: Types.GetTicketsQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketsQuery, Types.GetTicketsQueryVariables>(GetTicketsDocument, options)
}
export function useGetTicketsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketsQuery, Types.GetTicketsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketsQuery, Types.GetTicketsQueryVariables>(GetTicketsDocument, options)
}
export function useGetTicketsSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketsQuery, Types.GetTicketsQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketsQuery, Types.GetTicketsQueryVariables>(GetTicketsDocument, options)
}
export type GetTicketsQueryHookResult = ReturnType<typeof useGetTicketsQuery>
export type GetTicketsLazyQueryHookResult = ReturnType<typeof useGetTicketsLazyQuery>
export type GetTicketsSuspenseQueryHookResult = ReturnType<typeof useGetTicketsSuspenseQuery>
export type GetTicketsQueryResult = Apollo.QueryResult<Types.GetTicketsQuery, Types.GetTicketsQueryVariables>
export const GetTicketExistenceDocument = gql`
    query getTicketExistence($where: TicketWhereInput!) {
  tickets: allTickets(where: $where, first: 1) {
    id
  }
}
    `

/**
 * __useGetTicketExistenceQuery__
 *
 * To run a query within a React component, call `useGetTicketExistenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketExistenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketExistenceQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTicketExistenceQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketExistenceQuery, Types.GetTicketExistenceQueryVariables> & ({ variables: Types.GetTicketExistenceQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketExistenceQuery, Types.GetTicketExistenceQueryVariables>(GetTicketExistenceDocument, options)
}
export function useGetTicketExistenceLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketExistenceQuery, Types.GetTicketExistenceQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketExistenceQuery, Types.GetTicketExistenceQueryVariables>(GetTicketExistenceDocument, options)
}
export function useGetTicketExistenceSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketExistenceQuery, Types.GetTicketExistenceQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketExistenceQuery, Types.GetTicketExistenceQueryVariables>(GetTicketExistenceDocument, options)
}
export type GetTicketExistenceQueryHookResult = ReturnType<typeof useGetTicketExistenceQuery>
export type GetTicketExistenceLazyQueryHookResult = ReturnType<typeof useGetTicketExistenceLazyQuery>
export type GetTicketExistenceSuspenseQueryHookResult = ReturnType<typeof useGetTicketExistenceSuspenseQuery>
export type GetTicketExistenceQueryResult = Apollo.QueryResult<Types.GetTicketExistenceQuery, Types.GetTicketExistenceQueryVariables>
export const GetTicketsCountDocument = gql`
    query getTicketsCount($where: TicketWhereInput!) {
  meta: _allTicketsMeta(where: $where) {
    count
  }
}
    `

/**
 * __useGetTicketsCountQuery__
 *
 * To run a query within a React component, call `useGetTicketsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketsCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTicketsCountQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketsCountQuery, Types.GetTicketsCountQueryVariables> & ({ variables: Types.GetTicketsCountQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketsCountQuery, Types.GetTicketsCountQueryVariables>(GetTicketsCountDocument, options)
}
export function useGetTicketsCountLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketsCountQuery, Types.GetTicketsCountQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketsCountQuery, Types.GetTicketsCountQueryVariables>(GetTicketsCountDocument, options)
}
export function useGetTicketsCountSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketsCountQuery, Types.GetTicketsCountQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketsCountQuery, Types.GetTicketsCountQueryVariables>(GetTicketsCountDocument, options)
}
export type GetTicketsCountQueryHookResult = ReturnType<typeof useGetTicketsCountQuery>
export type GetTicketsCountLazyQueryHookResult = ReturnType<typeof useGetTicketsCountLazyQuery>
export type GetTicketsCountSuspenseQueryHookResult = ReturnType<typeof useGetTicketsCountSuspenseQuery>
export type GetTicketsCountQueryResult = Apollo.QueryResult<Types.GetTicketsCountQuery, Types.GetTicketsCountQueryVariables>
export const GetTicketsCountersByStatusDocument = gql`
    query getTicketsCountersByStatus($whereWithoutStatuses: TicketWhereInput!) {
  new_or_reopened: _allTicketsMeta(
    where: {AND: [$whereWithoutStatuses, {status: {type: new_or_reopened}}]}
  ) {
    count
  }
  processing: _allTicketsMeta(
    where: {AND: [$whereWithoutStatuses, {status: {type: processing}}]}
  ) {
    count
  }
  deferred: _allTicketsMeta(
    where: {AND: [$whereWithoutStatuses, {status: {type: deferred}}]}
  ) {
    count
  }
  canceled: _allTicketsMeta(
    where: {AND: [$whereWithoutStatuses, {status: {type: canceled}}]}
  ) {
    count
  }
  completed: _allTicketsMeta(
    where: {AND: [$whereWithoutStatuses, {status: {type: completed}}]}
  ) {
    count
  }
  closed: _allTicketsMeta(
    where: {AND: [$whereWithoutStatuses, {status: {type: closed}}]}
  ) {
    count
  }
}
    `

/**
 * __useGetTicketsCountersByStatusQuery__
 *
 * To run a query within a React component, call `useGetTicketsCountersByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketsCountersByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketsCountersByStatusQuery({
 *   variables: {
 *      whereWithoutStatuses: // value for 'whereWithoutStatuses'
 *   },
 * });
 */
export function useGetTicketsCountersByStatusQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketsCountersByStatusQuery, Types.GetTicketsCountersByStatusQueryVariables> & ({ variables: Types.GetTicketsCountersByStatusQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketsCountersByStatusQuery, Types.GetTicketsCountersByStatusQueryVariables>(GetTicketsCountersByStatusDocument, options)
}
export function useGetTicketsCountersByStatusLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketsCountersByStatusQuery, Types.GetTicketsCountersByStatusQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketsCountersByStatusQuery, Types.GetTicketsCountersByStatusQueryVariables>(GetTicketsCountersByStatusDocument, options)
}
export function useGetTicketsCountersByStatusSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketsCountersByStatusQuery, Types.GetTicketsCountersByStatusQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketsCountersByStatusQuery, Types.GetTicketsCountersByStatusQueryVariables>(GetTicketsCountersByStatusDocument, options)
}
export type GetTicketsCountersByStatusQueryHookResult = ReturnType<typeof useGetTicketsCountersByStatusQuery>
export type GetTicketsCountersByStatusLazyQueryHookResult = ReturnType<typeof useGetTicketsCountersByStatusLazyQuery>
export type GetTicketsCountersByStatusSuspenseQueryHookResult = ReturnType<typeof useGetTicketsCountersByStatusSuspenseQuery>
export type GetTicketsCountersByStatusQueryResult = Apollo.QueryResult<Types.GetTicketsCountersByStatusQuery, Types.GetTicketsCountersByStatusQueryVariables>
export const GetTicketByIdDocument = gql`
    query getTicketById($id: ID!) {
  tickets: allTickets(where: {id: $id}, first: 1) {
    id
    number
    propertyAddress
    propertyAddressMeta {
      ...AddressMetaForTableAddress
    }
    createdAt
    isEmergency
    isPayable
    isWarranty
    statusReopenedCounter
    statusUpdatedAt
    canReadByResident
    qualityControlValue
    qualityControlAdditionalOptions
    qualityControlComment
    feedbackValue
    feedbackAdditionalOptions
    feedbackComment
    deadline
    deferredUntil
    completedAt
    unitType
    unitName
    sectionName
    sectionType
    floorName
    isResidentTicket
    clientName
    clientPhone
    details
    isPayable
    organization {
      id
      type
      name
      statusTransitions
      country
    }
    assignee {
      id
    }
    executor {
      id
    }
    property {
      id
      deletedAt
      address
      addressMeta {
        ...AddressMetaForTableAddress
      }
    }
    classifier {
      id
      place {
        id
        name
      }
      category {
        id
        name
      }
      problem {
        id
        name
      }
    }
    createdBy {
      id
      name
      type
    }
    source {
      id
      name
    }
    status {
      id
      type
      name
      colors {
        primary
        secondary
      }
    }
    client {
      id
    }
    contact {
      id
      name
      phone
    }
  }
}
    ${AddressMetaForTableAddressFragmentDoc}`

/**
 * __useGetTicketByIdQuery__
 *
 * To run a query within a React component, call `useGetTicketByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTicketByIdQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketByIdQuery, Types.GetTicketByIdQueryVariables> & ({ variables: Types.GetTicketByIdQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketByIdQuery, Types.GetTicketByIdQueryVariables>(GetTicketByIdDocument, options)
}
export function useGetTicketByIdLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketByIdQuery, Types.GetTicketByIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketByIdQuery, Types.GetTicketByIdQueryVariables>(GetTicketByIdDocument, options)
}
export function useGetTicketByIdSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketByIdQuery, Types.GetTicketByIdQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketByIdQuery, Types.GetTicketByIdQueryVariables>(GetTicketByIdDocument, options)
}
export type GetTicketByIdQueryHookResult = ReturnType<typeof useGetTicketByIdQuery>
export type GetTicketByIdLazyQueryHookResult = ReturnType<typeof useGetTicketByIdLazyQuery>
export type GetTicketByIdSuspenseQueryHookResult = ReturnType<typeof useGetTicketByIdSuspenseQuery>
export type GetTicketByIdQueryResult = Apollo.QueryResult<Types.GetTicketByIdQuery, Types.GetTicketByIdQueryVariables>
export const GetTicketByCreatedByDocument = gql`
    query getTicketByCreatedBy($userId: ID!) {
  tickets: allTickets(where: {createdBy: {id: $userId}}, first: 1) {
    id
  }
}
    `

/**
 * __useGetTicketByCreatedByQuery__
 *
 * To run a query within a React component, call `useGetTicketByCreatedByQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketByCreatedByQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketByCreatedByQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetTicketByCreatedByQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketByCreatedByQuery, Types.GetTicketByCreatedByQueryVariables> & ({ variables: Types.GetTicketByCreatedByQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketByCreatedByQuery, Types.GetTicketByCreatedByQueryVariables>(GetTicketByCreatedByDocument, options)
}
export function useGetTicketByCreatedByLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketByCreatedByQuery, Types.GetTicketByCreatedByQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketByCreatedByQuery, Types.GetTicketByCreatedByQueryVariables>(GetTicketByCreatedByDocument, options)
}
export function useGetTicketByCreatedBySuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketByCreatedByQuery, Types.GetTicketByCreatedByQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketByCreatedByQuery, Types.GetTicketByCreatedByQueryVariables>(GetTicketByCreatedByDocument, options)
}
export type GetTicketByCreatedByQueryHookResult = ReturnType<typeof useGetTicketByCreatedByQuery>
export type GetTicketByCreatedByLazyQueryHookResult = ReturnType<typeof useGetTicketByCreatedByLazyQuery>
export type GetTicketByCreatedBySuspenseQueryHookResult = ReturnType<typeof useGetTicketByCreatedBySuspenseQuery>
export type GetTicketByCreatedByQueryResult = Apollo.QueryResult<Types.GetTicketByCreatedByQuery, Types.GetTicketByCreatedByQueryVariables>
export const GetTicketLastCommentsTimeDocument = gql`
    query getTicketLastCommentsTime($id: ID!) {
  ticketCommentTimes: allTickets(where: {id: $id}, first: 1) {
    id
    lastResidentCommentAt
    lastCommentWithResidentTypeAt
  }
}
    `

/**
 * __useGetTicketLastCommentsTimeQuery__
 *
 * To run a query within a React component, call `useGetTicketLastCommentsTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketLastCommentsTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketLastCommentsTimeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTicketLastCommentsTimeQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketLastCommentsTimeQuery, Types.GetTicketLastCommentsTimeQueryVariables> & ({ variables: Types.GetTicketLastCommentsTimeQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketLastCommentsTimeQuery, Types.GetTicketLastCommentsTimeQueryVariables>(GetTicketLastCommentsTimeDocument, options)
}
export function useGetTicketLastCommentsTimeLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketLastCommentsTimeQuery, Types.GetTicketLastCommentsTimeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketLastCommentsTimeQuery, Types.GetTicketLastCommentsTimeQueryVariables>(GetTicketLastCommentsTimeDocument, options)
}
export function useGetTicketLastCommentsTimeSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketLastCommentsTimeQuery, Types.GetTicketLastCommentsTimeQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketLastCommentsTimeQuery, Types.GetTicketLastCommentsTimeQueryVariables>(GetTicketLastCommentsTimeDocument, options)
}
export type GetTicketLastCommentsTimeQueryHookResult = ReturnType<typeof useGetTicketLastCommentsTimeQuery>
export type GetTicketLastCommentsTimeLazyQueryHookResult = ReturnType<typeof useGetTicketLastCommentsTimeLazyQuery>
export type GetTicketLastCommentsTimeSuspenseQueryHookResult = ReturnType<typeof useGetTicketLastCommentsTimeSuspenseQuery>
export type GetTicketLastCommentsTimeQueryResult = Apollo.QueryResult<Types.GetTicketLastCommentsTimeQuery, Types.GetTicketLastCommentsTimeQueryVariables>
export const GetContactTicketsDocument = gql`
    query getContactTickets($contactId: ID!) {
  tickets: allTickets(
    where: {contact: {id: $contactId}}
    sortBy: [createdAt_DESC]
  ) {
    id
    details
    createdAt
    number
    status {
      id
      name
    }
    property {
      id
      address
    }
  }
}
    `

/**
 * __useGetContactTicketsQuery__
 *
 * To run a query within a React component, call `useGetContactTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactTicketsQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useGetContactTicketsQuery (baseOptions: Apollo.QueryHookOptions<Types.GetContactTicketsQuery, Types.GetContactTicketsQueryVariables> & ({ variables: Types.GetContactTicketsQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetContactTicketsQuery, Types.GetContactTicketsQueryVariables>(GetContactTicketsDocument, options)
}
export function useGetContactTicketsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContactTicketsQuery, Types.GetContactTicketsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetContactTicketsQuery, Types.GetContactTicketsQueryVariables>(GetContactTicketsDocument, options)
}
export function useGetContactTicketsSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetContactTicketsQuery, Types.GetContactTicketsQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetContactTicketsQuery, Types.GetContactTicketsQueryVariables>(GetContactTicketsDocument, options)
}
export type GetContactTicketsQueryHookResult = ReturnType<typeof useGetContactTicketsQuery>
export type GetContactTicketsLazyQueryHookResult = ReturnType<typeof useGetContactTicketsLazyQuery>
export type GetContactTicketsSuspenseQueryHookResult = ReturnType<typeof useGetContactTicketsSuspenseQuery>
export type GetContactTicketsQueryResult = Apollo.QueryResult<Types.GetContactTicketsQuery, Types.GetContactTicketsQueryVariables>
export const UpdateTicketDocument = gql`
    mutation updateTicket($id: ID!, $data: TicketUpdateInput!) {
  ticket: updateTicket(id: $id, data: $data) {
    id
  }
}
    `
export type UpdateTicketMutationFn = Apollo.MutationFunction<Types.UpdateTicketMutation, Types.UpdateTicketMutationVariables>

/**
 * __useUpdateTicketMutation__
 *
 * To run a mutation, you first call `useUpdateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketMutation, { data, loading, error }] = useUpdateTicketMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTicketMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateTicketMutation, Types.UpdateTicketMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateTicketMutation, Types.UpdateTicketMutationVariables>(UpdateTicketDocument, options)
}
export type UpdateTicketMutationHookResult = ReturnType<typeof useUpdateTicketMutation>
export type UpdateTicketMutationResult = Apollo.MutationResult<Types.UpdateTicketMutation>
export type UpdateTicketMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTicketMutation, Types.UpdateTicketMutationVariables>
export const GetTicketChangesDocument = gql`
    query getTicketChanges($ticketId: ID!) {
  ticketChanges: allTicketChanges(
    where: {ticket: {id: $ticketId}}
    sortBy: [actualCreationDate_DESC]
  ) {
    id
    actualCreationDate
    createdAt
    createdBy {
      id
      name
    }
    changedByRole
    canReadByResidentFrom
    canReadByResidentTo
    deadlineFrom
    deadlineTo
    deferredUntilFrom
    deferredUntilTo
    statusReopenedCounterFrom
    statusReopenedCounterTo
    statusReasonFrom
    statusReasonTo
    clientNameFrom
    clientNameTo
    clientEmailFrom
    clientEmailTo
    clientPhoneFrom
    clientPhoneTo
    detailsFrom
    detailsTo
    isPaidFrom
    isPaidTo
    isPayableFrom
    isPayableTo
    isEmergencyFrom
    isEmergencyTo
    isWarrantyFrom
    isWarrantyTo
    metaFrom
    metaTo
    sectionNameFrom
    sectionNameTo
    sectionTypeFrom
    sectionTypeTo
    floorNameFrom
    floorNameTo
    unitNameFrom
    unitNameTo
    unitTypeFrom
    unitTypeTo
    sourceMetaFrom
    sourceMetaTo
    organizationIdFrom
    organizationIdTo
    organizationDisplayNameFrom
    organizationDisplayNameTo
    statusIdFrom
    statusIdTo
    statusDisplayNameFrom
    statusDisplayNameTo
    clientIdFrom
    clientIdTo
    clientDisplayNameFrom
    clientDisplayNameTo
    classifierIdFrom
    classifierIdTo
    classifierDisplayNameFrom
    classifierDisplayNameTo
    contactIdFrom
    contactIdTo
    contactDisplayNameFrom
    contactDisplayNameTo
    assigneeIdFrom
    assigneeIdTo
    assigneeDisplayNameFrom
    assigneeDisplayNameTo
    executorIdFrom
    executorIdTo
    executorDisplayNameFrom
    executorDisplayNameTo
    relatedIdFrom
    relatedIdTo
    relatedDisplayNameFrom
    relatedDisplayNameTo
    propertyIdFrom
    propertyIdTo
    propertyDisplayNameFrom
    propertyDisplayNameTo
    sourceIdFrom
    sourceIdTo
    sourceDisplayNameFrom
    sourceDisplayNameTo
    feedbackValueFrom
    feedbackValueTo
    feedbackCommentFrom
    feedbackCommentTo
    feedbackAdditionalOptionsFrom
    feedbackAdditionalOptionsTo
    qualityControlValueFrom
    qualityControlValueTo
    qualityControlCommentFrom
    qualityControlCommentTo
    qualityControlAdditionalOptionsFrom
    qualityControlAdditionalOptionsTo
  }
}
    `

/**
 * __useGetTicketChangesQuery__
 *
 * To run a query within a React component, call `useGetTicketChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketChangesQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGetTicketChangesQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketChangesQuery, Types.GetTicketChangesQueryVariables> & ({ variables: Types.GetTicketChangesQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketChangesQuery, Types.GetTicketChangesQueryVariables>(GetTicketChangesDocument, options)
}
export function useGetTicketChangesLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketChangesQuery, Types.GetTicketChangesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketChangesQuery, Types.GetTicketChangesQueryVariables>(GetTicketChangesDocument, options)
}
export function useGetTicketChangesSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketChangesQuery, Types.GetTicketChangesQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketChangesQuery, Types.GetTicketChangesQueryVariables>(GetTicketChangesDocument, options)
}
export type GetTicketChangesQueryHookResult = ReturnType<typeof useGetTicketChangesQuery>
export type GetTicketChangesLazyQueryHookResult = ReturnType<typeof useGetTicketChangesLazyQuery>
export type GetTicketChangesSuspenseQueryHookResult = ReturnType<typeof useGetTicketChangesSuspenseQuery>
export type GetTicketChangesQueryResult = Apollo.QueryResult<Types.GetTicketChangesQuery, Types.GetTicketChangesQueryVariables>
export const GetTicketCommentsDocument = gql`
    query getTicketComments($ticketId: ID!) {
  ticketComments: allTicketComments(
    where: {ticket: {id: $ticketId}}
    first: 100
    sortBy: [createdAt_DESC]
  ) {
    id
    type
    createdAt
    updatedAt
    deletedAt
    content
    user {
      id
      type
      name
    }
  }
}
    `

/**
 * __useGetTicketCommentsQuery__
 *
 * To run a query within a React component, call `useGetTicketCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketCommentsQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGetTicketCommentsQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketCommentsQuery, Types.GetTicketCommentsQueryVariables> & ({ variables: Types.GetTicketCommentsQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketCommentsQuery, Types.GetTicketCommentsQueryVariables>(GetTicketCommentsDocument, options)
}
export function useGetTicketCommentsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketCommentsQuery, Types.GetTicketCommentsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketCommentsQuery, Types.GetTicketCommentsQueryVariables>(GetTicketCommentsDocument, options)
}
export function useGetTicketCommentsSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketCommentsQuery, Types.GetTicketCommentsQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketCommentsQuery, Types.GetTicketCommentsQueryVariables>(GetTicketCommentsDocument, options)
}
export type GetTicketCommentsQueryHookResult = ReturnType<typeof useGetTicketCommentsQuery>
export type GetTicketCommentsLazyQueryHookResult = ReturnType<typeof useGetTicketCommentsLazyQuery>
export type GetTicketCommentsSuspenseQueryHookResult = ReturnType<typeof useGetTicketCommentsSuspenseQuery>
export type GetTicketCommentsQueryResult = Apollo.QueryResult<Types.GetTicketCommentsQuery, Types.GetTicketCommentsQueryVariables>
export const GetPollTicketCommentsDocument = gql`
    query getPollTicketComments($where: TicketCommentWhereInput!, $first: Int) {
  ticketComments: allTicketComments(
    where: $where
    first: $first
    sortBy: [updatedAt_DESC]
  ) {
    id
    updatedAt
    ticket {
      id
    }
  }
}
    `

/**
 * __useGetPollTicketCommentsQuery__
 *
 * To run a query within a React component, call `useGetPollTicketCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPollTicketCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPollTicketCommentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetPollTicketCommentsQuery (baseOptions: Apollo.QueryHookOptions<Types.GetPollTicketCommentsQuery, Types.GetPollTicketCommentsQueryVariables> & ({ variables: Types.GetPollTicketCommentsQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetPollTicketCommentsQuery, Types.GetPollTicketCommentsQueryVariables>(GetPollTicketCommentsDocument, options)
}
export function useGetPollTicketCommentsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPollTicketCommentsQuery, Types.GetPollTicketCommentsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetPollTicketCommentsQuery, Types.GetPollTicketCommentsQueryVariables>(GetPollTicketCommentsDocument, options)
}
export function useGetPollTicketCommentsSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetPollTicketCommentsQuery, Types.GetPollTicketCommentsQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetPollTicketCommentsQuery, Types.GetPollTicketCommentsQueryVariables>(GetPollTicketCommentsDocument, options)
}
export type GetPollTicketCommentsQueryHookResult = ReturnType<typeof useGetPollTicketCommentsQuery>
export type GetPollTicketCommentsLazyQueryHookResult = ReturnType<typeof useGetPollTicketCommentsLazyQuery>
export type GetPollTicketCommentsSuspenseQueryHookResult = ReturnType<typeof useGetPollTicketCommentsSuspenseQuery>
export type GetPollTicketCommentsQueryResult = Apollo.QueryResult<Types.GetPollTicketCommentsQuery, Types.GetPollTicketCommentsQueryVariables>
export const UpdateTicketCommentDocument = gql`
    mutation updateTicketComment($id: ID!, $data: TicketCommentUpdateInput!) {
  ticketComment: updateTicketComment(id: $id, data: $data) {
    id
  }
}
    `
export type UpdateTicketCommentMutationFn = Apollo.MutationFunction<Types.UpdateTicketCommentMutation, Types.UpdateTicketCommentMutationVariables>

/**
 * __useUpdateTicketCommentMutation__
 *
 * To run a mutation, you first call `useUpdateTicketCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketCommentMutation, { data, loading, error }] = useUpdateTicketCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTicketCommentMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateTicketCommentMutation, Types.UpdateTicketCommentMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateTicketCommentMutation, Types.UpdateTicketCommentMutationVariables>(UpdateTicketCommentDocument, options)
}
export type UpdateTicketCommentMutationHookResult = ReturnType<typeof useUpdateTicketCommentMutation>
export type UpdateTicketCommentMutationResult = Apollo.MutationResult<Types.UpdateTicketCommentMutation>
export type UpdateTicketCommentMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTicketCommentMutation, Types.UpdateTicketCommentMutationVariables>
export const CreateTicketCommentDocument = gql`
    mutation createTicketComment($data: TicketCommentCreateInput!) {
  ticketComment: createTicketComment(data: $data) {
    id
  }
}
    `
export type CreateTicketCommentMutationFn = Apollo.MutationFunction<Types.CreateTicketCommentMutation, Types.CreateTicketCommentMutationVariables>

/**
 * __useCreateTicketCommentMutation__
 *
 * To run a mutation, you first call `useCreateTicketCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketCommentMutation, { data, loading, error }] = useCreateTicketCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTicketCommentMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreateTicketCommentMutation, Types.CreateTicketCommentMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateTicketCommentMutation, Types.CreateTicketCommentMutationVariables>(CreateTicketCommentDocument, options)
}
export type CreateTicketCommentMutationHookResult = ReturnType<typeof useCreateTicketCommentMutation>
export type CreateTicketCommentMutationResult = Apollo.MutationResult<Types.CreateTicketCommentMutation>
export type CreateTicketCommentMutationOptions = Apollo.BaseMutationOptions<Types.CreateTicketCommentMutation, Types.CreateTicketCommentMutationVariables>
export const GetTicketCommentsFilesDocument = gql`
    query getTicketCommentsFiles($ticketCommentsIds: [ID!]!) {
  files: allTicketCommentFiles(
    where: {ticketComment: {id_in: $ticketCommentsIds}}
    first: 100
    sortBy: [createdAt_DESC]
  ) {
    id
    file {
      originalFilename
      mimetype
      publicUrl
    }
    ticketComment {
      id
    }
  }
}
    `

/**
 * __useGetTicketCommentsFilesQuery__
 *
 * To run a query within a React component, call `useGetTicketCommentsFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketCommentsFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketCommentsFilesQuery({
 *   variables: {
 *      ticketCommentsIds: // value for 'ticketCommentsIds'
 *   },
 * });
 */
export function useGetTicketCommentsFilesQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketCommentsFilesQuery, Types.GetTicketCommentsFilesQueryVariables> & ({ variables: Types.GetTicketCommentsFilesQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketCommentsFilesQuery, Types.GetTicketCommentsFilesQueryVariables>(GetTicketCommentsFilesDocument, options)
}
export function useGetTicketCommentsFilesLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketCommentsFilesQuery, Types.GetTicketCommentsFilesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketCommentsFilesQuery, Types.GetTicketCommentsFilesQueryVariables>(GetTicketCommentsFilesDocument, options)
}
export function useGetTicketCommentsFilesSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketCommentsFilesQuery, Types.GetTicketCommentsFilesQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketCommentsFilesQuery, Types.GetTicketCommentsFilesQueryVariables>(GetTicketCommentsFilesDocument, options)
}
export type GetTicketCommentsFilesQueryHookResult = ReturnType<typeof useGetTicketCommentsFilesQuery>
export type GetTicketCommentsFilesLazyQueryHookResult = ReturnType<typeof useGetTicketCommentsFilesLazyQuery>
export type GetTicketCommentsFilesSuspenseQueryHookResult = ReturnType<typeof useGetTicketCommentsFilesSuspenseQuery>
export type GetTicketCommentsFilesQueryResult = Apollo.QueryResult<Types.GetTicketCommentsFilesQuery, Types.GetTicketCommentsFilesQueryVariables>
export const GetTicketDocumentGenerationTasksDocument = gql`
    query getTicketDocumentGenerationTasks($where: TicketDocumentGenerationTaskWhereInput!) {
  tasks: allTicketDocumentGenerationTasks(where: $where) {
    id
    progress
    status
    file {
      publicUrl
      originalFilename
    }
    __typename
  }
}
    `

/**
 * __useGetTicketDocumentGenerationTasksQuery__
 *
 * To run a query within a React component, call `useGetTicketDocumentGenerationTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketDocumentGenerationTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketDocumentGenerationTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTicketDocumentGenerationTasksQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketDocumentGenerationTasksQuery, Types.GetTicketDocumentGenerationTasksQueryVariables> & ({ variables: Types.GetTicketDocumentGenerationTasksQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketDocumentGenerationTasksQuery, Types.GetTicketDocumentGenerationTasksQueryVariables>(GetTicketDocumentGenerationTasksDocument, options)
}
export function useGetTicketDocumentGenerationTasksLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketDocumentGenerationTasksQuery, Types.GetTicketDocumentGenerationTasksQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketDocumentGenerationTasksQuery, Types.GetTicketDocumentGenerationTasksQueryVariables>(GetTicketDocumentGenerationTasksDocument, options)
}
export function useGetTicketDocumentGenerationTasksSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketDocumentGenerationTasksQuery, Types.GetTicketDocumentGenerationTasksQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketDocumentGenerationTasksQuery, Types.GetTicketDocumentGenerationTasksQueryVariables>(GetTicketDocumentGenerationTasksDocument, options)
}
export type GetTicketDocumentGenerationTasksQueryHookResult = ReturnType<typeof useGetTicketDocumentGenerationTasksQuery>
export type GetTicketDocumentGenerationTasksLazyQueryHookResult = ReturnType<typeof useGetTicketDocumentGenerationTasksLazyQuery>
export type GetTicketDocumentGenerationTasksSuspenseQueryHookResult = ReturnType<typeof useGetTicketDocumentGenerationTasksSuspenseQuery>
export type GetTicketDocumentGenerationTasksQueryResult = Apollo.QueryResult<Types.GetTicketDocumentGenerationTasksQuery, Types.GetTicketDocumentGenerationTasksQueryVariables>
export const CreateTicketDocumentGenerationTaskDocument = gql`
    mutation createTicketDocumentGenerationTask($data: TicketDocumentGenerationTaskCreateInput!) {
  task: createTicketDocumentGenerationTask(data: $data) {
    id
    progress
    status
    file {
      publicUrl
      originalFilename
    }
    __typename
  }
}
    `
export type CreateTicketDocumentGenerationTaskMutationFn = Apollo.MutationFunction<Types.CreateTicketDocumentGenerationTaskMutation, Types.CreateTicketDocumentGenerationTaskMutationVariables>

/**
 * __useCreateTicketDocumentGenerationTaskMutation__
 *
 * To run a mutation, you first call `useCreateTicketDocumentGenerationTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketDocumentGenerationTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketDocumentGenerationTaskMutation, { data, loading, error }] = useCreateTicketDocumentGenerationTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTicketDocumentGenerationTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreateTicketDocumentGenerationTaskMutation, Types.CreateTicketDocumentGenerationTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateTicketDocumentGenerationTaskMutation, Types.CreateTicketDocumentGenerationTaskMutationVariables>(CreateTicketDocumentGenerationTaskDocument, options)
}
export type CreateTicketDocumentGenerationTaskMutationHookResult = ReturnType<typeof useCreateTicketDocumentGenerationTaskMutation>
export type CreateTicketDocumentGenerationTaskMutationResult = Apollo.MutationResult<Types.CreateTicketDocumentGenerationTaskMutation>
export type CreateTicketDocumentGenerationTaskMutationOptions = Apollo.BaseMutationOptions<Types.CreateTicketDocumentGenerationTaskMutation, Types.CreateTicketDocumentGenerationTaskMutationVariables>
export const UpdateTicketDocumentGenerationTaskDocument = gql`
    mutation updateTicketDocumentGenerationTask($id: ID!, $data: TicketDocumentGenerationTaskUpdateInput!) {
  task: updateTicketDocumentGenerationTask(data: $data, id: $id) {
    id
    progress
    status
    file {
      publicUrl
      originalFilename
    }
    __typename
  }
}
    `
export type UpdateTicketDocumentGenerationTaskMutationFn = Apollo.MutationFunction<Types.UpdateTicketDocumentGenerationTaskMutation, Types.UpdateTicketDocumentGenerationTaskMutationVariables>

/**
 * __useUpdateTicketDocumentGenerationTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTicketDocumentGenerationTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketDocumentGenerationTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketDocumentGenerationTaskMutation, { data, loading, error }] = useUpdateTicketDocumentGenerationTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTicketDocumentGenerationTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateTicketDocumentGenerationTaskMutation, Types.UpdateTicketDocumentGenerationTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateTicketDocumentGenerationTaskMutation, Types.UpdateTicketDocumentGenerationTaskMutationVariables>(UpdateTicketDocumentGenerationTaskDocument, options)
}
export type UpdateTicketDocumentGenerationTaskMutationHookResult = ReturnType<typeof useUpdateTicketDocumentGenerationTaskMutation>
export type UpdateTicketDocumentGenerationTaskMutationResult = Apollo.MutationResult<Types.UpdateTicketDocumentGenerationTaskMutation>
export type UpdateTicketDocumentGenerationTaskMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTicketDocumentGenerationTaskMutation, Types.UpdateTicketDocumentGenerationTaskMutationVariables>
export const GetTicketExportTasksDocument = gql`
    query getTicketExportTasks($where: TicketExportTaskWhereInput!) {
  tasks: allTicketExportTasks(where: $where) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
}
    `

/**
 * __useGetTicketExportTasksQuery__
 *
 * To run a query within a React component, call `useGetTicketExportTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketExportTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketExportTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTicketExportTasksQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketExportTasksQuery, Types.GetTicketExportTasksQueryVariables> & ({ variables: Types.GetTicketExportTasksQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketExportTasksQuery, Types.GetTicketExportTasksQueryVariables>(GetTicketExportTasksDocument, options)
}
export function useGetTicketExportTasksLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketExportTasksQuery, Types.GetTicketExportTasksQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketExportTasksQuery, Types.GetTicketExportTasksQueryVariables>(GetTicketExportTasksDocument, options)
}
export function useGetTicketExportTasksSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketExportTasksQuery, Types.GetTicketExportTasksQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketExportTasksQuery, Types.GetTicketExportTasksQueryVariables>(GetTicketExportTasksDocument, options)
}
export type GetTicketExportTasksQueryHookResult = ReturnType<typeof useGetTicketExportTasksQuery>
export type GetTicketExportTasksLazyQueryHookResult = ReturnType<typeof useGetTicketExportTasksLazyQuery>
export type GetTicketExportTasksSuspenseQueryHookResult = ReturnType<typeof useGetTicketExportTasksSuspenseQuery>
export type GetTicketExportTasksQueryResult = Apollo.QueryResult<Types.GetTicketExportTasksQuery, Types.GetTicketExportTasksQueryVariables>
export const CreateTicketExportTaskDocument = gql`
    mutation createTicketExportTask($data: TicketExportTaskCreateInput!) {
  task: createTicketExportTask(data: $data) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
}
    `
export type CreateTicketExportTaskMutationFn = Apollo.MutationFunction<Types.CreateTicketExportTaskMutation, Types.CreateTicketExportTaskMutationVariables>

/**
 * __useCreateTicketExportTaskMutation__
 *
 * To run a mutation, you first call `useCreateTicketExportTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketExportTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketExportTaskMutation, { data, loading, error }] = useCreateTicketExportTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTicketExportTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreateTicketExportTaskMutation, Types.CreateTicketExportTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateTicketExportTaskMutation, Types.CreateTicketExportTaskMutationVariables>(CreateTicketExportTaskDocument, options)
}
export type CreateTicketExportTaskMutationHookResult = ReturnType<typeof useCreateTicketExportTaskMutation>
export type CreateTicketExportTaskMutationResult = Apollo.MutationResult<Types.CreateTicketExportTaskMutation>
export type CreateTicketExportTaskMutationOptions = Apollo.BaseMutationOptions<Types.CreateTicketExportTaskMutation, Types.CreateTicketExportTaskMutationVariables>
export const UpdateTicketExportTaskDocument = gql`
    mutation updateTicketExportTask($id: ID!, $data: TicketExportTaskUpdateInput!) {
  task: updateTicketExportTask(data: $data, id: $id) {
    id
    status
    file {
      publicUrl
      originalFilename
    }
    totalRecordsCount
    exportedRecordsCount
    __typename
  }
}
    `
export type UpdateTicketExportTaskMutationFn = Apollo.MutationFunction<Types.UpdateTicketExportTaskMutation, Types.UpdateTicketExportTaskMutationVariables>

/**
 * __useUpdateTicketExportTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTicketExportTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketExportTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketExportTaskMutation, { data, loading, error }] = useUpdateTicketExportTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTicketExportTaskMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateTicketExportTaskMutation, Types.UpdateTicketExportTaskMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateTicketExportTaskMutation, Types.UpdateTicketExportTaskMutationVariables>(UpdateTicketExportTaskDocument, options)
}
export type UpdateTicketExportTaskMutationHookResult = ReturnType<typeof useUpdateTicketExportTaskMutation>
export type UpdateTicketExportTaskMutationResult = Apollo.MutationResult<Types.UpdateTicketExportTaskMutation>
export type UpdateTicketExportTaskMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTicketExportTaskMutation, Types.UpdateTicketExportTaskMutationVariables>
export const GetTicketPropertyHintByIdDocument = gql`
    query getTicketPropertyHintById($id: ID!) {
  ticketPropertyHints: allTicketPropertyHints(where: {id: $id}, first: 1) {
    id
    organization {
      id
    }
    name
    content
  }
}
    `

/**
 * __useGetTicketPropertyHintByIdQuery__
 *
 * To run a query within a React component, call `useGetTicketPropertyHintByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketPropertyHintByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketPropertyHintByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTicketPropertyHintByIdQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketPropertyHintByIdQuery, Types.GetTicketPropertyHintByIdQueryVariables> & ({ variables: Types.GetTicketPropertyHintByIdQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketPropertyHintByIdQuery, Types.GetTicketPropertyHintByIdQueryVariables>(GetTicketPropertyHintByIdDocument, options)
}
export function useGetTicketPropertyHintByIdLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketPropertyHintByIdQuery, Types.GetTicketPropertyHintByIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketPropertyHintByIdQuery, Types.GetTicketPropertyHintByIdQueryVariables>(GetTicketPropertyHintByIdDocument, options)
}
export function useGetTicketPropertyHintByIdSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketPropertyHintByIdQuery, Types.GetTicketPropertyHintByIdQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketPropertyHintByIdQuery, Types.GetTicketPropertyHintByIdQueryVariables>(GetTicketPropertyHintByIdDocument, options)
}
export type GetTicketPropertyHintByIdQueryHookResult = ReturnType<typeof useGetTicketPropertyHintByIdQuery>
export type GetTicketPropertyHintByIdLazyQueryHookResult = ReturnType<typeof useGetTicketPropertyHintByIdLazyQuery>
export type GetTicketPropertyHintByIdSuspenseQueryHookResult = ReturnType<typeof useGetTicketPropertyHintByIdSuspenseQuery>
export type GetTicketPropertyHintByIdQueryResult = Apollo.QueryResult<Types.GetTicketPropertyHintByIdQuery, Types.GetTicketPropertyHintByIdQueryVariables>
export const GetTicketPropertyHintPropertyByPropertyDocument = gql`
    query getTicketPropertyHintPropertyByProperty($propertyId: ID!) {
  ticketPropertyHintProperty: allTicketPropertyHintProperties(
    where: {property: {id: $propertyId}}
    first: 1
  ) {
    id
    ticketPropertyHint {
      id
    }
  }
}
    `

/**
 * __useGetTicketPropertyHintPropertyByPropertyQuery__
 *
 * To run a query within a React component, call `useGetTicketPropertyHintPropertyByPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketPropertyHintPropertyByPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketPropertyHintPropertyByPropertyQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetTicketPropertyHintPropertyByPropertyQuery (baseOptions: Apollo.QueryHookOptions<Types.GetTicketPropertyHintPropertyByPropertyQuery, Types.GetTicketPropertyHintPropertyByPropertyQueryVariables> & ({ variables: Types.GetTicketPropertyHintPropertyByPropertyQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketPropertyHintPropertyByPropertyQuery, Types.GetTicketPropertyHintPropertyByPropertyQueryVariables>(GetTicketPropertyHintPropertyByPropertyDocument, options)
}
export function useGetTicketPropertyHintPropertyByPropertyLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketPropertyHintPropertyByPropertyQuery, Types.GetTicketPropertyHintPropertyByPropertyQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketPropertyHintPropertyByPropertyQuery, Types.GetTicketPropertyHintPropertyByPropertyQueryVariables>(GetTicketPropertyHintPropertyByPropertyDocument, options)
}
export function useGetTicketPropertyHintPropertyByPropertySuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketPropertyHintPropertyByPropertyQuery, Types.GetTicketPropertyHintPropertyByPropertyQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketPropertyHintPropertyByPropertyQuery, Types.GetTicketPropertyHintPropertyByPropertyQueryVariables>(GetTicketPropertyHintPropertyByPropertyDocument, options)
}
export type GetTicketPropertyHintPropertyByPropertyQueryHookResult = ReturnType<typeof useGetTicketPropertyHintPropertyByPropertyQuery>
export type GetTicketPropertyHintPropertyByPropertyLazyQueryHookResult = ReturnType<typeof useGetTicketPropertyHintPropertyByPropertyLazyQuery>
export type GetTicketPropertyHintPropertyByPropertySuspenseQueryHookResult = ReturnType<typeof useGetTicketPropertyHintPropertyByPropertySuspenseQuery>
export type GetTicketPropertyHintPropertyByPropertyQueryResult = Apollo.QueryResult<Types.GetTicketPropertyHintPropertyByPropertyQuery, Types.GetTicketPropertyHintPropertyByPropertyQueryVariables>
export const GetTicketSourcesDocument = gql`
    query getTicketSources($types: [TicketSourceTypeType!]) {
  sources: allTicketSources(where: {type_in: $types}) {
    id
    name
  }
}
    `

/**
 * __useGetTicketSourcesQuery__
 *
 * To run a query within a React component, call `useGetTicketSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketSourcesQuery({
 *   variables: {
 *      types: // value for 'types'
 *   },
 * });
 */
export function useGetTicketSourcesQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetTicketSourcesQuery, Types.GetTicketSourcesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketSourcesQuery, Types.GetTicketSourcesQueryVariables>(GetTicketSourcesDocument, options)
}
export function useGetTicketSourcesLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketSourcesQuery, Types.GetTicketSourcesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketSourcesQuery, Types.GetTicketSourcesQueryVariables>(GetTicketSourcesDocument, options)
}
export function useGetTicketSourcesSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketSourcesQuery, Types.GetTicketSourcesQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketSourcesQuery, Types.GetTicketSourcesQueryVariables>(GetTicketSourcesDocument, options)
}
export type GetTicketSourcesQueryHookResult = ReturnType<typeof useGetTicketSourcesQuery>
export type GetTicketSourcesLazyQueryHookResult = ReturnType<typeof useGetTicketSourcesLazyQuery>
export type GetTicketSourcesSuspenseQueryHookResult = ReturnType<typeof useGetTicketSourcesSuspenseQuery>
export type GetTicketSourcesQueryResult = Apollo.QueryResult<Types.GetTicketSourcesQuery, Types.GetTicketSourcesQueryVariables>
export const GetTicketStatusesDocument = gql`
    query getTicketStatuses {
  statuses: allTicketStatuses {
    id
    name
    type
    colors {
      primary
      secondary
    }
  }
}
    `

/**
 * __useGetTicketStatusesQuery__
 *
 * To run a query within a React component, call `useGetTicketStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTicketStatusesQuery (baseOptions?: Apollo.QueryHookOptions<Types.GetTicketStatusesQuery, Types.GetTicketStatusesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketStatusesQuery, Types.GetTicketStatusesQueryVariables>(GetTicketStatusesDocument, options)
}
export function useGetTicketStatusesLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketStatusesQuery, Types.GetTicketStatusesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketStatusesQuery, Types.GetTicketStatusesQueryVariables>(GetTicketStatusesDocument, options)
}
export function useGetTicketStatusesSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetTicketStatusesQuery, Types.GetTicketStatusesQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetTicketStatusesQuery, Types.GetTicketStatusesQueryVariables>(GetTicketStatusesDocument, options)
}
export type GetTicketStatusesQueryHookResult = ReturnType<typeof useGetTicketStatusesQuery>
export type GetTicketStatusesLazyQueryHookResult = ReturnType<typeof useGetTicketStatusesLazyQuery>
export type GetTicketStatusesSuspenseQueryHookResult = ReturnType<typeof useGetTicketStatusesSuspenseQuery>
export type GetTicketStatusesQueryResult = Apollo.QueryResult<Types.GetTicketStatusesQuery, Types.GetTicketStatusesQueryVariables>
export const GetUserFavoriteTicketsDocument = gql`
    query getUserFavoriteTickets($userId: ID!, $ticketWhere: TicketWhereInput) {
  userFavoriteTickets: allUserFavoriteTickets(
    where: {user: {id: $userId}, ticket: $ticketWhere}
    first: 500
  ) {
    id
    ticket {
      id
    }
  }
  meta: _allUserFavoriteTicketsMeta(
    where: {user: {id: $userId}, ticket: $ticketWhere}
  ) {
    count
  }
}
    `

/**
 * __useGetUserFavoriteTicketsQuery__
 *
 * To run a query within a React component, call `useGetUserFavoriteTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFavoriteTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFavoriteTicketsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      ticketWhere: // value for 'ticketWhere'
 *   },
 * });
 */
export function useGetUserFavoriteTicketsQuery (baseOptions: Apollo.QueryHookOptions<Types.GetUserFavoriteTicketsQuery, Types.GetUserFavoriteTicketsQueryVariables> & ({ variables: Types.GetUserFavoriteTicketsQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetUserFavoriteTicketsQuery, Types.GetUserFavoriteTicketsQueryVariables>(GetUserFavoriteTicketsDocument, options)
}
export function useGetUserFavoriteTicketsLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserFavoriteTicketsQuery, Types.GetUserFavoriteTicketsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetUserFavoriteTicketsQuery, Types.GetUserFavoriteTicketsQueryVariables>(GetUserFavoriteTicketsDocument, options)
}
export function useGetUserFavoriteTicketsSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetUserFavoriteTicketsQuery, Types.GetUserFavoriteTicketsQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetUserFavoriteTicketsQuery, Types.GetUserFavoriteTicketsQueryVariables>(GetUserFavoriteTicketsDocument, options)
}
export type GetUserFavoriteTicketsQueryHookResult = ReturnType<typeof useGetUserFavoriteTicketsQuery>
export type GetUserFavoriteTicketsLazyQueryHookResult = ReturnType<typeof useGetUserFavoriteTicketsLazyQuery>
export type GetUserFavoriteTicketsSuspenseQueryHookResult = ReturnType<typeof useGetUserFavoriteTicketsSuspenseQuery>
export type GetUserFavoriteTicketsQueryResult = Apollo.QueryResult<Types.GetUserFavoriteTicketsQuery, Types.GetUserFavoriteTicketsQueryVariables>
export const GetUserTicketCommentsReadTimeDocument = gql`
    query getUserTicketCommentsReadTime($userId: ID!, $ticketIds: [ID!]) {
  objs: allUserTicketCommentReadTimes(
    where: {user: {id: $userId}, ticket: {id_in: $ticketIds}}
  ) {
    id
    readResidentCommentAt
  }
}
    `

/**
 * __useGetUserTicketCommentsReadTimeQuery__
 *
 * To run a query within a React component, call `useGetUserTicketCommentsReadTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTicketCommentsReadTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTicketCommentsReadTimeQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      ticketIds: // value for 'ticketIds'
 *   },
 * });
 */
export function useGetUserTicketCommentsReadTimeQuery (baseOptions: Apollo.QueryHookOptions<Types.GetUserTicketCommentsReadTimeQuery, Types.GetUserTicketCommentsReadTimeQueryVariables> & ({ variables: Types.GetUserTicketCommentsReadTimeQueryVariables, skip?: boolean } | { skip: boolean }) ) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetUserTicketCommentsReadTimeQuery, Types.GetUserTicketCommentsReadTimeQueryVariables>(GetUserTicketCommentsReadTimeDocument, options)
}
export function useGetUserTicketCommentsReadTimeLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserTicketCommentsReadTimeQuery, Types.GetUserTicketCommentsReadTimeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetUserTicketCommentsReadTimeQuery, Types.GetUserTicketCommentsReadTimeQueryVariables>(GetUserTicketCommentsReadTimeDocument, options)
}
export function useGetUserTicketCommentsReadTimeSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.GetUserTicketCommentsReadTimeQuery, Types.GetUserTicketCommentsReadTimeQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.GetUserTicketCommentsReadTimeQuery, Types.GetUserTicketCommentsReadTimeQueryVariables>(GetUserTicketCommentsReadTimeDocument, options)
}
export type GetUserTicketCommentsReadTimeQueryHookResult = ReturnType<typeof useGetUserTicketCommentsReadTimeQuery>
export type GetUserTicketCommentsReadTimeLazyQueryHookResult = ReturnType<typeof useGetUserTicketCommentsReadTimeLazyQuery>
export type GetUserTicketCommentsReadTimeSuspenseQueryHookResult = ReturnType<typeof useGetUserTicketCommentsReadTimeSuspenseQuery>
export type GetUserTicketCommentsReadTimeQueryResult = Apollo.QueryResult<Types.GetUserTicketCommentsReadTimeQuery, Types.GetUserTicketCommentsReadTimeQueryVariables>
export const CreateUserTicketCommentReadTimeDocument = gql`
    mutation createUserTicketCommentReadTime($data: UserTicketCommentReadTimeCreateInput) {
  obj: createUserTicketCommentReadTime(data: $data) {
    id
  }
}
    `
export type CreateUserTicketCommentReadTimeMutationFn = Apollo.MutationFunction<Types.CreateUserTicketCommentReadTimeMutation, Types.CreateUserTicketCommentReadTimeMutationVariables>

/**
 * __useCreateUserTicketCommentReadTimeMutation__
 *
 * To run a mutation, you first call `useCreateUserTicketCommentReadTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserTicketCommentReadTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserTicketCommentReadTimeMutation, { data, loading, error }] = useCreateUserTicketCommentReadTimeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserTicketCommentReadTimeMutation (baseOptions?: Apollo.MutationHookOptions<Types.CreateUserTicketCommentReadTimeMutation, Types.CreateUserTicketCommentReadTimeMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateUserTicketCommentReadTimeMutation, Types.CreateUserTicketCommentReadTimeMutationVariables>(CreateUserTicketCommentReadTimeDocument, options)
}
export type CreateUserTicketCommentReadTimeMutationHookResult = ReturnType<typeof useCreateUserTicketCommentReadTimeMutation>
export type CreateUserTicketCommentReadTimeMutationResult = Apollo.MutationResult<Types.CreateUserTicketCommentReadTimeMutation>
export type CreateUserTicketCommentReadTimeMutationOptions = Apollo.BaseMutationOptions<Types.CreateUserTicketCommentReadTimeMutation, Types.CreateUserTicketCommentReadTimeMutationVariables>
export const UpdateUserTicketCommentReadTimeDocument = gql`
    mutation updateUserTicketCommentReadTime($id: ID!, $data: UserTicketCommentReadTimeUpdateInput) {
  obj: updateUserTicketCommentReadTime(id: $id, data: $data) {
    id
  }
}
    `
export type UpdateUserTicketCommentReadTimeMutationFn = Apollo.MutationFunction<Types.UpdateUserTicketCommentReadTimeMutation, Types.UpdateUserTicketCommentReadTimeMutationVariables>

/**
 * __useUpdateUserTicketCommentReadTimeMutation__
 *
 * To run a mutation, you first call `useUpdateUserTicketCommentReadTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTicketCommentReadTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTicketCommentReadTimeMutation, { data, loading, error }] = useUpdateUserTicketCommentReadTimeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserTicketCommentReadTimeMutation (baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserTicketCommentReadTimeMutation, Types.UpdateUserTicketCommentReadTimeMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateUserTicketCommentReadTimeMutation, Types.UpdateUserTicketCommentReadTimeMutationVariables>(UpdateUserTicketCommentReadTimeDocument, options)
}
export type UpdateUserTicketCommentReadTimeMutationHookResult = ReturnType<typeof useUpdateUserTicketCommentReadTimeMutation>
export type UpdateUserTicketCommentReadTimeMutationResult = Apollo.MutationResult<Types.UpdateUserTicketCommentReadTimeMutation>
export type UpdateUserTicketCommentReadTimeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserTicketCommentReadTimeMutation, Types.UpdateUserTicketCommentReadTimeMutationVariables>
export const AuthenticatedUserDocument = gql`
    query authenticatedUser {
  authenticatedUser {
    id
    name
    avatar {
      publicUrl
    }
    phone
    email
    isAdmin
    isSupport
    rightsSet {
      id
    }
    locale
    showGlobalHints
  }
}
    `

/**
 * __useAuthenticatedUserQuery__
 *
 * To run a query within a React component, call `useAuthenticatedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticatedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticatedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthenticatedUserQuery (baseOptions?: Apollo.QueryHookOptions<Types.AuthenticatedUserQuery, Types.AuthenticatedUserQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.AuthenticatedUserQuery, Types.AuthenticatedUserQueryVariables>(AuthenticatedUserDocument, options)
}
export function useAuthenticatedUserLazyQuery (baseOptions?: Apollo.LazyQueryHookOptions<Types.AuthenticatedUserQuery, Types.AuthenticatedUserQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.AuthenticatedUserQuery, Types.AuthenticatedUserQueryVariables>(AuthenticatedUserDocument, options)
}
export function useAuthenticatedUserSuspenseQuery (baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<Types.AuthenticatedUserQuery, Types.AuthenticatedUserQueryVariables>) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
    return Apollo.useSuspenseQuery<Types.AuthenticatedUserQuery, Types.AuthenticatedUserQueryVariables>(AuthenticatedUserDocument, options)
}
export type AuthenticatedUserQueryHookResult = ReturnType<typeof useAuthenticatedUserQuery>
export type AuthenticatedUserLazyQueryHookResult = ReturnType<typeof useAuthenticatedUserLazyQuery>
export type AuthenticatedUserSuspenseQueryHookResult = ReturnType<typeof useAuthenticatedUserSuspenseQuery>
export type AuthenticatedUserQueryResult = Apollo.QueryResult<Types.AuthenticatedUserQuery, Types.AuthenticatedUserQueryVariables>
export const SignOutDocument = gql`
    mutation signOut {
  unauthenticateUser {
    success
  }
}
    `
export type SignOutMutationFn = Apollo.MutationFunction<Types.SignOutMutation, Types.SignOutMutationVariables>

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation (baseOptions?: Apollo.MutationHookOptions<Types.SignOutMutation, Types.SignOutMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.SignOutMutation, Types.SignOutMutationVariables>(SignOutDocument, options)
}
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>
export type SignOutMutationResult = Apollo.MutationResult<Types.SignOutMutation>
export type SignOutMutationOptions = Apollo.BaseMutationOptions<Types.SignOutMutation, Types.SignOutMutationVariables>